import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { Scenes, sitemap } from '../sitemap';
import { API, APIUtils, getHTTPCodeFromTRPCError } from '../../api/trpc';
import { RouterErrorHandler } from '../error/RouterErrorHandler';
import { Loading } from '../../components/Loading';
import { createFirmwareViewerLoader } from '../../components/Backoffice/Firmware/loaders';
import { createHardwareViewerLoader } from '../../components/Backoffice/Hardware/loaders';
import { AdminTemplate, FullAuthTemplate, SmallAuthTemplate } from '../../components/Layout/Template';
import { variableViewerLoader } from '../../components/Backoffice/Variables/loaders';
import { cultivationRecordTypeViewerLoader } from '../../scenes/Backoffice/ContentManager/CultivationRecordType/loaders';
import { identifiersViewerLoader } from '../../components/Backoffice/Identifiers/loaders';
import { zoneTypeViewerLoader } from '../../components/Backoffice/ZoneType/loaders';
import { useSessionUser } from '../../session';
import { AuthFallbackElement } from './FallbackElement';
import { lotViewerLoader } from '../../components/Backoffice/Stock/Lot/loaders';
import { mainboardViewerLoader } from '../../components/Backoffice/Stock/Mainboard/loaders';
import { NavigatePendingDevice, NavigteWithQueryParams } from '../utils';
import { TRPCClientError } from '@trpc/client';

const authAppLoader = (utils: APIUtils, id: number) => async () => {
  try {
    const data = await utils.organizations.get.ensureData({ id });

    return data;
  } catch (e) {
    if (e instanceof TRPCClientError) {
      const status = getHTTPCodeFromTRPCError(e);
      if (status) {
        throw new Response(e.message, { status });
      }
    }

    throw e;
  }
};

export const AuthRouter: React.FC = () => {
  const user = useSessionUser();
  const defaultOrganization = Number(user.defaultOrganization);

  const utils = API.useUtils();
  const router = createBrowserRouter([
    {
      path: '/',
      loader: authAppLoader(utils, defaultOrganization),
      errorElement: <RouterErrorHandler />,
      children: [
        {
          path: sitemap[Scenes.BACKOFFICE].path,
          element: <AdminTemplate />,
          errorElement: <RouterErrorHandler />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Backoffice/Index'),
              hydrateFallbackElement: <AuthFallbackElement />,
            },
            {
              path: sitemap[Scenes.BACKOFFICE].children.contentManager.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Backoffice/ContentManager/Index'),
                  hydrateFallbackElement: <AuthFallbackElement />,
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.cultivationRecordType.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/CultivationRecordType/Index'),
                      hydrateFallbackElement: <AuthFallbackElement />,
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.cultivationRecordType.children.viewer.dynamicPath,
                      loader: cultivationRecordTypeViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/CultivationRecordType/Viewer'),
                    },
                    {
                      path: '*',
                      element: (
                        <NavigteWithQueryParams
                          to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.cultivationRecordType.path}
                        />
                      ),
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.firmware.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Firmware/Index'),
                      hydrateFallbackElement: <AuthFallbackElement />,
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.firmware.children.viewer.dynamicPath,
                      loader: createFirmwareViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Firmware/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.firmware.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.hardware.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Hardware/Index'),
                      hydrateFallbackElement: <AuthFallbackElement />,
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.hardware.children.viewer.dynamicPath,
                      loader: createHardwareViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Hardware/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.hardware.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.variable.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Variable/Index'),
                      hydrateFallbackElement: <AuthFallbackElement />,
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.variable.children.viewer.dynamicPath,
                      loader: variableViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Variable/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.variable.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.identifiers.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Identifiers/Index'),
                      hydrateFallbackElement: <AuthFallbackElement />,
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.identifiers.children.viewer.dynamicPath,
                      loader: identifiersViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Identifiers/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.identifiers.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.zoneType.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/ZoneType/Index'),
                      hydrateFallbackElement: <AuthFallbackElement />,
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.zoneType.children.viewer.dynamicPath,
                      loader: zoneTypeViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/ZoneType/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.zoneType.path} />,
                    },
                  ],
                },
              ],
            },
            {
              path: sitemap[Scenes.BACKOFFICE].children.stockManager.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Backoffice/StockManager/Index'),
                  hydrateFallbackElement: <AuthFallbackElement />,
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.stockManager.children.lot.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/StockManager/Lot/Index'),
                      hydrateFallbackElement: <AuthFallbackElement />,
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.stockManager.children.lot.children.viewer.dynamicPath,
                      loader: lotViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/StockManager/Lot/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.stockManager.children.lot.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.stockManager.children.mainboard.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/StockManager/Mainboard/Index'),
                      hydrateFallbackElement: <AuthFallbackElement />,
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.stockManager.children.mainboard.children.viewer.dynamicPath,
                      loader: mainboardViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/StockManager/Mainboard/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.stockManager.children.mainboard.path} />,
                    },
                  ],
                },
              ],
            },
            {
              path: sitemap[Scenes.BACKOFFICE].children.tools.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Backoffice/Tools/Index'),
                  hydrateFallbackElement: <AuthFallbackElement />,
                },
                {
                  path: '*',
                  element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.tools.path} />,
                },
              ],
            },
          ],
        },
        {
          path: sitemap[Scenes.ANALYTICS].path,
          element: <FullAuthTemplate />,
          errorElement: <RouterErrorHandler />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Analytics/Index'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.ANALYTICS].path} />,
            },
          ],
          hydrateFallbackElement: <Loading />,
        },
        {
          path: sitemap[Scenes.AUTOMATIONS].path,
          element: <FullAuthTemplate />,
          errorElement: <RouterErrorHandler />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Automations/Index'),
            },
            {
              path: sitemap[Scenes.AUTOMATIONS].children.advanced.path,
              lazy: () => import('../../scenes/Automations/Advanced'),
            },
            {
              path: sitemap[Scenes.AUTOMATIONS].children.creator.path,
              lazy: () => import('../../scenes/Automations/Creator'),
            },
            {
              path: sitemap[Scenes.AUTOMATIONS].children.viewer.dynamicPath,
              lazy: () => import('../../scenes/Automations/Viewer'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.AUTOMATIONS].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.CULTIVATION_LOGS].path,
          element: <FullAuthTemplate />,
          errorElement: <RouterErrorHandler />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/CultivationLogs/Index'),
            },
            {
              path: sitemap[Scenes.CULTIVATION_LOGS].children.viewer.dynamicPath,
              lazy: () => import('../../scenes/CultivationLogs/Viewer'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.CULTIVATION_LOGS].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.DEVICES].path,
          element: <FullAuthTemplate />,
          errorElement: <RouterErrorHandler />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Devices/Index'),
            },
            {
              path: sitemap[Scenes.DEVICES].children.devices.dynamicPath,
              lazy: () => import('../../scenes/Devices/Device'),
            },
            {
              path: sitemap[Scenes.DEVICES].children.modules.dynamicPath,
              lazy: () => import('../../scenes/Devices/Module'),
            },
            {
              path: sitemap[Scenes.DEVICES].children.ios.dynamicPath,
              lazy: () => import('../../scenes/Devices/IO'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.DEVICES].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.MY_CULTIVATION].path,
          element: <FullAuthTemplate />,
          errorElement: <RouterErrorHandler />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/MyCultivation/Index'),
            },
            {
              path: sitemap[Scenes.MY_CULTIVATION].children.zone.dynamicPath,
              lazy: () => import('../../scenes/MyCultivation/Zone'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.MY_CULTIVATION].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.ORGANIZATION].path,
          element: <FullAuthTemplate />,
          errorElement: <RouterErrorHandler />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Organization/Index'),
            },
            {
              path: sitemap[Scenes.ORGANIZATION].children.user.dynamicPath,
              lazy: () => import('../../scenes/Organization/UserProfile'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.ORGANIZATION].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.PROFILE].path,
          element: <SmallAuthTemplate />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Profile/Index'),
            },
            {
              path: sitemap[Scenes.PROFILE].children.emailVerification.path,
              lazy: () => import('../../scenes/Profile/AttributeVerification'),
            },
            {
              path: sitemap[Scenes.PROFILE].children.notifications.path,
              lazy: () => import('../../scenes/Profile/Notifications'),
            },
            {
              path: sitemap[Scenes.PROFILE].children.phoneVerification.path,
              lazy: () => import('../../scenes/Profile/AttributeVerification'),
            },
            {
              path: sitemap[Scenes.PROFILE].children.preferences.path,
              lazy: () => import('../../scenes/Profile/Preferences'),
            },
            {
              path: sitemap[Scenes.PROFILE].children.security.path,
              lazy: () => import('../../scenes/Profile/Security'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.PROFILE].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.SUPPORT].path,
          element: <FullAuthTemplate />,
          errorElement: <RouterErrorHandler />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Support/Index'),
            },
            {
              path: sitemap[Scenes.SUPPORT].children.issue.dynamicPath,
              lazy: () => import('../../scenes/Support/Issue'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.SUPPORT].path} />,
            },
          ],
        },
        {
          index: true,
          element: <Navigate to={sitemap[Scenes.MY_CULTIVATION].path} />,
        },
      ],
    },
    {
      path: '*',
      element: <NavigatePendingDevice />,
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<AuthFallbackElement />} />;
};
