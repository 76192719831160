import { createTRPCReact, TRPCClientErrorLike, type inferReactQueryProcedureOptions } from '@trpc/react-query';
import type { AppRouter, APIInputs as APIInputsType, APIOutputs as APIOutputType } from '../../../server/src/api';

export const API = createTRPCReact<AppRouter>({
  abortOnUnmount: true,
});

export type API = AppRouter;
export type APIOptions = inferReactQueryProcedureOptions<AppRouter>;
export type APIInputs = APIInputsType;
export type APIOutputs = APIOutputType;
export type APIUtils = ReturnType<ReturnType<typeof createTRPCReact<AppRouter>>['useUtils']>;

export const getHTTPCodeFromTRPCError = (error: TRPCClientErrorLike<API>): number | null => {
  if ('data' in error && typeof error.data === 'object' && error.data !== null) {
    if ('httpStatus' in error.data) {
      return Number(error.data.httpStatus);
    }
  }
  return null;
};
