import React from 'react';
type InitRef<T> = () => T;

export function useLazyRef<T>(init: InitRef<T>) {
  const ref = React.useRef<T | null>(null);

  if (ref.current === null) {
    ref.current = init();
  }

  return ref.current;
}
