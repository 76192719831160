import React from 'react';
import './ErrorPage.css';
import { RELOAD_COUNT_CACHE_KEY } from '../../routing/error/RouterErrorHandler';
/**
 * There is no translation here because this can be shown outside the translation provider.
 * Additionally, we cannot use Chakra components because there is no context either.
 *
 * TODO: implement intl by using static messages and browser lang
 */
export const ErrorPage: React.FC = () => {
  const reload = () => {
    window.location.reload();
  };

  React.useEffect(() => {
    localStorage.removeItem(RELOAD_COUNT_CACHE_KEY);
  }, []);

  return (
    <div className="error-page-container">
      <div className="error-page-content">
        <h1>Error inesperado</h1>
        <p>Intente recargar la página para solucionarlo. Si el problema persiste, contáctenos.</p>
        <button className="reload-button" onClick={reload}>
          Recargar
        </button>
      </div>
    </div>
  );
};
