import { useSession } from '.';

export const useSignOut = () => {
  const { isLoggedIn, cleanSession, user } = useSession();

  const signOut = () => {
    if (isLoggedIn) {
      user.signOut();
    }

    cleanSession();
  };

  return {
    signOut,
  };
};
