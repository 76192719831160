import React from 'react';

import { motion, isValidMotionProp } from 'framer-motion';
import Logo from '../../src/assets/logos/isotipo.png';

import {
  chakra,
  shouldForwardProp,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Skeleton,
  Spacer,
  Spinner,
  SpinnerProps,
  VStack,
  ImageProps,
} from '@chakra-ui/react';

interface Props extends SpinnerProps {
  size?: 'sm' | 'md' | 'lg';
}

export const Loading: React.FC<Props> = (props) => {
  return (
    <Center flex={1}>
      <Spinner color="brand.500" label="loading" {...props} />
    </Center>
  );
};

export const CommonEditorSkeleton: React.FC = () => {
  return (
    <Flex direction={'column'} maxWidth={'600px'} margin={'auto'}>
      <Card>
        <CardHeader>
          <Flex alignItems={'center'}>
            <Skeleton width={'40%'} endColor={'bg500'} startColor="bg100" height={8} />
            <Spacer />
            <Skeleton width={'8%'} endColor={'bg500'} startColor="bg100" height={9} />
          </Flex>
        </CardHeader>
        <CardBody>
          <VStack spacing={10} py={4} alignItems={'start'}>
            <Skeleton width={'60%'} endColor={'bg500'} startColor="bg100" height={7} />
            <Skeleton width={'30%'} endColor={'bg500'} startColor="bg100" height={7} />
            <Skeleton width={'45%'} endColor={'bg500'} startColor="bg100" height={7} />
            <Skeleton width={'25%'} endColor={'bg500'} startColor="bg100" height={7} />
            <Skeleton width={'40%'} endColor={'bg500'} startColor="bg100" height={7} />
          </VStack>
        </CardBody>
        <CardFooter justifyContent={'end'}>
          <Skeleton width={'12%'} endColor={'bg500'} startColor="bg100" height={10} />
        </CardFooter>
      </Card>
    </Flex>
  );
};

const ConnectionIcon = chakra(motion.img, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const AppLoading: React.FC<ImageProps> = (props) => {
  const transition = {
    duration: 3,
    ease: 'easeInOut',
    repeat: Infinity,
    repeatType: 'loop',
  };

  const animate = {
    rotate: [0, 270, -270, 100, -100],
    // scale: [0.9, 1, 0.9, 1, 0.9],
    filter: ['grayscale(100%)', 'grayscale(0%)', 'grayscale(100%)', 'grayscale(0%)', 'grayscale(100%)'],
  };

  return (
    // @ts-ignore no problem in operation, although type error appears.
    <ConnectionIcon animate={animate} transition={transition} display={'flex'} alignItems={'center'} src={Logo} alt="logo" {...props} />
  );
};
