import { Card, CardBody, CardHeader, Flex, Skeleton } from '@chakra-ui/react';

export const LogsSkeleton = () => {
  return (
    <Card>
      <CardHeader>
        <Flex>
          <Skeleton width={48} endColor={'bg500'} startColor="bg100" height={10} borderRadius={'md'} />
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction={'column'} gap={3} width={'100%'}>
          <Flex direction={'column'} gap={4} py={2} borderRadius="lg" bg="bg500" width={'100%'}>
            <Flex flexDirection={'column'} py={2} px={4} gap={2}>
              <Skeleton py={2} height={4} width={'20%'} endColor={'bg500'} startColor="bg100" />
              <Skeleton height={3} width={'70%'} endColor={'bg500'} startColor="bg100" />
            </Flex>
            <Flex flexDirection={'column'} py={2} px={4} gap={2}>
              <Skeleton py={2} height={4} width={'20%'} endColor={'bg500'} startColor="bg100" />
              <Skeleton height={3} width={'70%'} endColor={'bg500'} startColor="bg100" />
            </Flex>
            <Flex flexDirection={'column'} py={2} px={4} gap={2}>
              <Skeleton py={2} height={4} width={'20%'} endColor={'bg500'} startColor="bg100" />
              <Skeleton height={3} width={'70%'} endColor={'bg500'} startColor="bg100" />
            </Flex>
            <Flex flexDirection={'column'} py={2} px={4} gap={2}>
              <Skeleton py={2} height={4} width={'20%'} endColor={'bg500'} startColor="bg100" />
              <Skeleton height={3} width={'70%'} endColor={'bg500'} startColor="bg100" />
            </Flex>
            <Flex flexDirection={'column'} py={2} px={4} gap={2}>
              <Skeleton py={2} height={4} width={'20%'} endColor={'bg500'} startColor="bg100" />
              <Skeleton height={3} width={'70%'} endColor={'bg500'} startColor="bg100" />
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};
