import { mqtt5 } from 'aws-iot-device-sdk-v2';
import { JobExecutionStatus, validateJobExecutionStatus, validateNonEmptyObject, validateObjectKey, validateString } from 'common';
import createDebugger from 'debug';

const debug = createDebugger('Growcast:Jobs:utils');

export interface JobExecutionInfo {
  id: string;
  status: JobExecutionStatus;
  device: number;
}

export const parseJobExecutionMessage = (eventData: mqtt5.MessageReceivedEvent): null | JobExecutionInfo => {
  try {
    if (eventData.message.payload) {
      const payload = JSON.parse(eventData.message.payload.toString()) as unknown;

      validateNonEmptyObject(payload);
      validateObjectKey(payload, 'status');
      validateObjectKey(payload, 'jobId');
      validateObjectKey(payload, 'thingArn');

      const { status, jobId, thingArn } = payload;

      validateString(jobId);
      validateString(thingArn);
      validateString(status);

      validateJobExecutionStatus(status);

      const regex = /thing\/([^/]+)_([0-9]+)/;
      const match = regex.exec(thingArn);
      if (match && match.length >= 3) {
        const device = Number(match[2]);

        return {
          id: jobId,
          status,
          device,
        };
      }

      throw new Error('device id not found');
    }

    return null;
  } catch (e) {
    debug(e);
    return null;
  }
};

export const extractJobExecutionStatusFromMessage = (eventData: mqtt5.MessageReceivedEvent): null | JobExecutionStatus => {
  try {
    if (eventData.message.payload) {
      const payload = JSON.parse(eventData.message.payload.toString()) as unknown;
      debug({ payload }, eventData.message);

      validateNonEmptyObject(payload);
      validateObjectKey(payload, 'status');

      const { status } = payload;
      validateString(status);
      validateJobExecutionStatus(status);

      return status;
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const getExecutionStatusColor = (status: JobExecutionStatus): 'info' | 'warning' | 'success' | 'error' | 'loading' => {
  switch (status) {
    case JobExecutionStatus.QUEUED:
    case JobExecutionStatus.IN_PROGRESS:
      return 'loading';
    case JobExecutionStatus.SUCCEEDED:
      return 'success';
    case JobExecutionStatus.FAILED:
    case JobExecutionStatus.TIMED_OUT:
    case JobExecutionStatus.REJECTED:
      return 'error';
    case JobExecutionStatus.REMOVED:
    case JobExecutionStatus.CANCELED:
      return 'warning';
    default:
      return 'info';
  }
};

type TerminalJobExecutionStatus =
  | JobExecutionStatus.SUCCEEDED
  | JobExecutionStatus.FAILED
  | JobExecutionStatus.TIMED_OUT
  | JobExecutionStatus.CANCELED
  | JobExecutionStatus.REMOVED
  | JobExecutionStatus.REJECTED;

export const isTerminalJobExecutionStatus = (status: JobExecutionStatus): status is TerminalJobExecutionStatus => {
  const terminalStatuses = [
    JobExecutionStatus.SUCCEEDED,
    JobExecutionStatus.FAILED,
    JobExecutionStatus.TIMED_OUT,
    JobExecutionStatus.CANCELED,
    JobExecutionStatus.REMOVED,
    JobExecutionStatus.REJECTED,
  ];

  return terminalStatuses.includes(status);
};
