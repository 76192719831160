import { InternalPermissionsType, isValidRole, PermissionsObject } from './permissions';

// naming patterns
export const getEnvPrefix = (env: string): string => {
  return env.substring(0, 3);
};

// this is shared with lambdas
export const createDeviceClientIdPrefix = (env: string): string => {
  const prefix = getEnvPrefix(env);

  return `${prefix}-device`;
};

export const createDeviceClientId = (env: string, deviceId: number): string => {
  const prefix = createDeviceClientIdPrefix(env);

  return `${prefix}_${deviceId}`;
};

/**
 * TODO:
 * -replace utf8 with unicode in db
 * -re enable unicode chars
 */

/**
 * Normalizes a username to be used in policy names.
 * This function uses Unicode Normalization Form KC (NFKD) to decompose combined characters into their canonical equivalents.
 * It then replaces any character that is not a Unicode alphanumeric character, '.', or '@' with an asterisk.
 * This ensures that the username is in a format that is safe to use in policy names.
 *
 * @param username The username to be normalized.
 * @returns The normalized username.
 */
export const normalizeUsernameForPolicy = (username: string): string => {
  // Allow only Unicode alphanumeric characters and @
  // return username.normalize('NFKD').replace(/[^\p{L}\p{N}@.]/gu, '*');

  /**
   * Just remove special characters until we move utf8 to unicode in the db schema
   * appending * we differenciate normal users from ones with special chars
   */
  return username.normalize('NFKD').replace(/[^\p{L}\p{N}]/gu, '*');
};

export const createDevicePolicyName = (env: string, organizationId: number, deviceId: number): string => {
  const prefix = getEnvPrefix(env);
  return `${prefix}-policy-org_${organizationId}-device_${deviceId}`;
};

export const createUserPolicyName = (env: string, username: string): string => {
  const prefix = getEnvPrefix(env);
  const normalizedUsername = normalizeUsernameForPolicy(username);

  return `${prefix}-policy-user_${normalizedUsername}`;
};

export const createBillingGroupName = (env: string, organizationId: number): string => {
  const prefix = getEnvPrefix(env);
  return `${prefix}-billing-org_${organizationId}`;
};

/**
 * Extracts the device ID number from a given client ID using a slice from character index 11.
 * Assumes the client ID format follows "{prefix}_{deviceId}" and the prefix is exactly 10 characters long.
 * @param clientId The client ID from which to extract the device ID.
 * @returns The extracted device ID as a number, or null if extraction fails.
 */
export const extractDeviceIdFromClientId = (clientId: string): number | null => {
  const deviceIdPart = clientId.slice(11);

  if (!deviceIdPart) {
    return null;
  }

  const asNumber = parseInt(deviceIdPart, 10);
  return isNaN(asNumber) ? null : asNumber;
};

/**
 * Generate list of allowed topics based on the user permissions
 * Wildcard is editable because is different for subscribing (#) and receiving (*)
 * in the policy document
 *
 * Zones can include IOs from several devices so, we have to allow all the devices trafic
 * I know it's a security issue, but it's only inside the same organization, and it still hidden
 * for users, because they don't know other devices id and they have to do a lot of inverse ingenieer
 * to realize they can subscribe to other devices/
 * */
export const getTopicsBasedOnPermissions = (permissions: PermissionsObject, wildcard = '*', ignoreAdmin?: boolean): Array<string> => {
  const { roles, organizations } = permissions;

  const topics = [];

  // any global role means the user can subscribe to everything
  if (roles.size > 0 && !ignoreAdmin) {
    topics.push(wildcard);
  } else {
    organizations.forEach((organization) => {
      let shouldAllowAllDevices = false;

      // similar behavior at organization level
      if ('role' in organization && isValidRole(organization.role)) {
        shouldAllowAllDevices = true;
      } else if (organization.internal) {
        organization.internal.forEach((internal) => {
          if (internal.type === InternalPermissionsType.DEVICE) {
            topics.push(`${organization.id}/${internal.id}/${wildcard}`);
          } else {
            // this is more permissive than it supposed to be, it should allow devices with corresponding permissions
            shouldAllowAllDevices = true;
          }
        });
      }

      if (shouldAllowAllDevices) {
        topics.push(`${organization.id}/${wildcard}`);
      }
    });
  }

  return topics;
};
