import { assertUnreachable } from './validations';
import timezones from 'timezones-list';
import { getTimezoneOffset } from 'date-fns-tz';
/**
 * This file contains interfaces and util fn to manage dates,
 * like fixed periods definitions for histories and parser
 */
export enum HistoryPeriod {
  last24hours = 'LAST_24_HOURS',
  last3Days = 'LAST_3_DAYS',
  lastWeek = 'LAST_WEEK',
  last2Weeks = 'LAST_2_WEEKS',
  lastMonth = 'LAST_MONTH',
  last3Months = 'LAST_3_MONTHS',
  last6Months = 'LAST_6_MONTHS',
  lastYear = 'LAST_YEAR',
}

export function parsePeriodToDate(period: HistoryPeriod): Date {
  const from = new Date();

  switch (period) {
    case HistoryPeriod.last24hours:
      from.setDate(new Date().getDate() - 1);
      break;
    case HistoryPeriod.last3Days:
      from.setDate(new Date().getDate() - 3);
      break;
    case HistoryPeriod.lastWeek:
      from.setDate(new Date().getDate() - 7);
      break;
    case HistoryPeriod.last2Weeks:
      from.setDate(new Date().getDate() - 14);
      break;
    case HistoryPeriod.lastMonth:
      from.setMonth(new Date().getMonth() - 1);
      break;
    case HistoryPeriod.last3Months:
      from.setMonth(new Date().getMonth() - 3);
      break;
    case HistoryPeriod.last6Months:
      from.setMonth(new Date().getMonth() - 6);
      break;
    case HistoryPeriod.lastYear:
      from.setFullYear(new Date().getFullYear() - 1);
      break;
    default:
      assertUnreachable('invalid period', period);
  }

  return from;
}
interface TimeParts {
  hours: number;
  minutes: number;
  seconds: number;
}
export const extractTimeParts = (dateString: string | Date): null | TimeParts => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    null;
  }

  return {
    hours: date.getUTCHours(),
    minutes: date.getUTCMinutes(),
    seconds: date.getUTCSeconds(),
  };
};

export enum DaysOfWeek {
  SUNDAY = '0',
  MONDAY = '1',
  TUESDAY = '2',
  WEDNESDAY = '3',
  THURSDAY = '4',
  FRIDAY = '5',
  SATURDAY = '6',
}

/**
 *  Function to list all timezones
 */
export function getTimezones() {
  return timezones;
}

/**
 * get timezone offset in seconds for devices
 */

export const getTimezoneOffsetInSeconds = (timezone: string) => {
  const offsetInMilliSeconds = getTimezoneOffset(timezone);

  return Math.round(offsetInMilliSeconds / 1000);
};

export const getStartOfDay = (date: Date): Date => {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
};

export const getEndOfDay = (date: Date): Date => {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
};

export const isStartOfDay = (date: Date): boolean => {
  return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
};

export const isEndOfDay = (date: Date): boolean => {
  return date.getHours() === 23 && date.getMinutes() === 59 && date.getSeconds() === 59;
};

export const formatTimeZone = (timezone: string, utc?: string) => {
  const zonePart = timezone.split('/').join(', ').replace(/_/g, ' ');

  if (utc) {
    return `${zonePart} (${utc})`;
  }

  return zonePart;
};
