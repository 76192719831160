import React from 'react';
import { useMQTTClient } from '../../broker/useMQTTClient';
import { JobExecutionStatus } from 'common';
import { mqtt5 } from 'aws-iot-device-sdk-v2';
import { JobExecutionInfo, isTerminalJobExecutionStatus, parseJobExecutionMessage } from './utils';
import { useToast } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import createDebugger from 'debug';
import { statusDescriptionMessages } from './JobMessages';

const debug = createDebugger('Growcast:JobExecutionProvider');

type Callback<T> = (args: T) => void;
type ExecutionListener = (jobId: string, name: string, onSuccess?: Callback<JobExecutionInfo>, onReject?: Callback<void>) => void;
export interface JobExecutionListener {
  subscribeToJobExecution: ExecutionListener;
}
export const JobExecutionContext = React.createContext<JobExecutionListener | null>(null);

interface Props {
  children: React.ReactNode;
}

const JOB_EXECUTION_TOAST_TIMEOUT = 90_000;

export const JobExecutionProvider: React.FC<Props> = (props) => {
  const [timeouts, setTimeouts] = React.useState<Map<string, NodeJS.Timeout>>(new Map());

  debug('initialinzing', timeouts);
  const intl = useIntl();
  const toast = useToast();

  const { subscribe: mqttSubscribe, isConnected } = useMQTTClient();

  // TODO: add unsubscribe method from mqttSubscribe
  const subscribeToJobExecution = React.useCallback<ExecutionListener>(
    (jobId, name, onSuccess, onReject, timeoutDuration = JOB_EXECUTION_TOAST_TIMEOUT) => {
      // restrict to subscribe more than one job per io
      if (!isConnected) {
        debug('Client disconnected');
        return;
      }

      let error = JobExecutionStatus.FAILED;
      const promise = new Promise<void>((resolve, reject) => {
        const timeoutId: NodeJS.Timeout = setTimeout(() => {
          error = JobExecutionStatus.TIMED_OUT;

          // Reject the promise if the job times out
          reject(new Error(`Job ${jobId} timed out`));
        }, timeoutDuration);

        setTimeouts((prevTimeouts) => {
          const newTimeouts = new Map(prevTimeouts);
          newTimeouts.set(jobId, timeoutId);
          return newTimeouts;
        });

        const toSubscribe = (info: JobExecutionInfo) => {
          if (isTerminalJobExecutionStatus(info.status)) {
            clearTimeout(timeoutId);

            debug('Terminal status', info.status);

            // if its terminal, only resolve the toast on success
            if (info.status === JobExecutionStatus.SUCCEEDED) {
              if (onSuccess) {
                debug('invoking onSuccess');
                onSuccess(info);
              }

              resolve();
            } else {
              if (onReject) {
                debug('invoking onReject');
                onReject();
              }

              reject();
            }
          } else {
            /**
             *  not terminal means pending or queued.
             *  so, just keep waiting
             */
            debug('non terminal status: ', info.status);
          }
        };

        const handler = (eventData: mqtt5.MessageReceivedEvent): void => {
          const jobExecutionInfo = parseJobExecutionMessage(eventData);
          if (!jobExecutionInfo) {
            debug('invalid job info');
            return;
          }

          toSubscribe(jobExecutionInfo);
        };

        debug(`subscribed t ${jobId}`);
        void mqttSubscribe(`$aws/events/jobExecution/${jobId}/#`, handler).catch((error) => debug('Error subscribing', error));
      });

      toast.promise(promise, {
        success: {
          title: name,
          description: intl.formatMessage(statusDescriptionMessages[JobExecutionStatus.SUCCEEDED]),
        },
        error: {
          title: name,
          description: intl.formatMessage(statusDescriptionMessages[error]),
        },
        loading: {
          title: name,
          description: intl.formatMessage(statusDescriptionMessages[JobExecutionStatus.IN_PROGRESS]),
        },
      });
    },
    [isConnected, mqttSubscribe, toast, intl]
  );

  React.useEffect(() => {
    return () => {
      debug('clear timeouts', timeouts);
      timeouts.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    };
  }, [timeouts]);

  const value = {
    subscribeToJobExecution,
  };

  return <JobExecutionContext.Provider value={value}>{props.children}</JobExecutionContext.Provider>;
};
