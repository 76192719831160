import { Alarm, AlarmType, validateAlarm, validateAlarmType } from './alarms';
import { MinifiedAutomation, validateMinifiedAutomation } from './automations';
import { JobAction, JobExecutionStatus, validateJobAction, validateJobExecutionStatus } from './jobs';
import { validateNonEmptyObject, validateObjectKey, validateString, validateNumber, assertUnreachable } from './validations';

/**
 * Due own permission system, we need organization, device and io at the top
 * level when they exist to allow us to perform searches based on permissions
 */
export interface BaseEvent {
  created: string;
}

/**
 * not sure if it's needed but just keeping spaces for new types.
 * btw: they are numbers to keep them compatible with devices
 */
export enum EventType {
  SYSTEM = 'system',
  ORGANIZATION = 'organization',
  ALARM = 'alarm',
}

export enum OrganizationEventName {
  DEVICE_ADDED = 'devicedAdded',
  DEVICE_CONNECTED = 'deviceConnected',
  DEVICE_DELETED = 'deviceDeleted',
  DEVICE_DISCONNECTED = 'deviceDisconnected',
  DEVICE_RESET = 'deviceReset',
  DEVICE_SYNC = 'deviceSync',
  DEVICE_UPDATED = 'deviceUpdated',
  DEVICE_WIFI_CREDENTIALS_DELETED = 'deviceWifiCredentialsDeleted',
  IO_ALARM_ADDED = 'ioAlarmAdded',
  IO_ALARM_DELETED = 'ioAlarmDeleted',
  IO_ALARM_UPDATED = 'ioAlarmUpdated',
  IO_AUTOMATION_SET = 'ioAutomationSet',
  IO_MANUAL_STATE_SET = 'ioManualStateSet',
  JOB_EXECUTION_FAILED = 'jobExecutionFailed',
  MODULE_ADDED = 'moduleAdded',
  MODULE_DELETED = 'moduleDeleted',
  MODULE_CONNECTED = 'moduleConnected',
  MODULE_DISCONNECTED = 'moduleDisconnected',
  USER_JOINED = 'userJoined',
}

/**
 *
 * Organization event payload
 *
 */

// device added
export interface DeviceAddedEventPayload {
  device: number;
  author: string;
}

export interface DeviceAddedEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.DEVICE_ADDED;
  organization: number;
  payload: DeviceAddedEventPayload;
}

// device connected
export interface DeviceConnectedEvent extends BaseEvent {
  organization: number;
  device: number;
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.DEVICE_CONNECTED;
}

// device disconnected
/**
 * We should keep the device id in the payload because
 * the relation will be no longer availabe in the database
 * So, we also need to save the name for visualization
 */
export interface DeviceDeletedEventPayload {
  author: string;
  deviceId: number;
  deviceName: string;
}
export interface DeviceDeletedEvent extends BaseEvent {
  organization: number;
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.DEVICE_DELETED;
  payload: DeviceDeletedEventPayload;
}

// device disconnected
export interface DeviceDisconnectedEvent extends BaseEvent {
  organization: number;
  device: number;
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.DEVICE_DISCONNECTED;
}

// device reset
export interface DeviceResetEventPayload {
  author: string;
}
export interface DeviceResetEvent extends BaseEvent {
  organization: number;
  device: number;
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.DEVICE_RESET;
  payload: DeviceResetEventPayload;
}

// device sync
export interface DeviceSyncEventPayload {
  author: string;
}
export interface DeviceSyncEvent extends BaseEvent {
  organization: number;
  device: number;
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.DEVICE_SYNC;
  payload: DeviceSyncEventPayload;
}

// device updated
export interface DeviceUpdatedEventPayload {
  expectedVersion: number;
  author: string;
}
export interface DeviceUpdatedEvent extends BaseEvent {
  organization: number;
  device: number;
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.DEVICE_UPDATED;
  payload: DeviceUpdatedEventPayload;
}

// device wifi credentials deleted
export interface DeviceWifiCredentialsDeletedEventPayload {
  author: string;
}

export interface DeviceWifiCredentialsDeletedEvent extends BaseEvent {
  organization: number;
  device: number;
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.DEVICE_WIFI_CREDENTIALS_DELETED;
  payload: DeviceWifiCredentialsDeletedEventPayload;
}

// user joined
export interface UserJoinedEventPayload {
  username: string;
}

export interface UserJoinedEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.USER_JOINED;
  organization: number;
  payload: UserJoinedEventPayload;
}

// io alarm added
export interface IOAlarmAddedOrUpdatedEventPayload {
  id: number;
  raw: Alarm;
  author: string;
}

export interface IOAlarmAddedEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.IO_ALARM_ADDED;
  organization: number;
  device: number;
  io: number;
  payload: IOAlarmAddedOrUpdatedEventPayload;
}

// io alarm deleted
export interface IOAlarmDeletedEventPayload {
  id: number;
  type: AlarmType;
  author: string;
}
export interface IOAlarmDeletedEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.IO_ALARM_DELETED;
  organization: number;
  device: number;
  io: number;
  payload: IOAlarmDeletedEventPayload;
}

// io alarm updated
export interface IOAlarmUpdatedEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.IO_ALARM_UPDATED;
  organization: number;
  device: number;
  io: number;
  payload: IOAlarmAddedOrUpdatedEventPayload;
}

// io automation set
export interface IOAutomationSetEventPayload {
  raw: MinifiedAutomation;
  automation: number;
  author: string;
}

export interface IOAutomationSetEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.IO_AUTOMATION_SET;
  organization: number;
  device: number;
  io: number;
  payload: IOAutomationSetEventPayload;
}

// io job execution failed
export interface JobExecutionFailedEventPayload {
  status: JobExecutionStatus;
  action: JobAction;
  jobId: string;
  author: string;
}

export interface JobExecutionFailedEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.JOB_EXECUTION_FAILED;
  organization: number;
  device: number;
  io: number | null;
  payload: JobExecutionFailedEventPayload;
}

// io manual state set
export interface IOManualStateSetEventPayload {
  state: number;
  author: string;
}
export interface IOManualStateSetEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.IO_MANUAL_STATE_SET;
  organization: number;
  device: number;
  io: number;
  payload: IOManualStateSetEventPayload;
}

// module added
export interface ModuleAddedEventPayload {
  module: number;
  author: string;
}

export interface ModuleAddedEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.MODULE_ADDED;
  organization: number;
  device: number;
  payload: ModuleAddedEventPayload;
}

// module deleted
export interface ModuleDeletedEventPayload {
  moduleName: string;
  author: string;
}

export interface ModuleDeletedEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.MODULE_DELETED;
  organization: number;
  device: number;
  payload: ModuleDeletedEventPayload;
}

// module connected
export interface ModuleConnectedEventPayload {
  module: number;
}
export interface ModuleConnectedEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.MODULE_CONNECTED;
  organization: number;
  device: number;
  payload: ModuleConnectedEventPayload;
}

// module disconnected
export interface ModuleDisconnectedEventPayload {
  module: number;
}

export interface ModuleDisconnectedEvent extends BaseEvent {
  type: EventType.ORGANIZATION;
  name: OrganizationEventName.MODULE_DISCONNECTED;
  organization: number;
  device: number;
  payload: ModuleDisconnectedEventPayload;
}

export type OrganizationEvent =
  | DeviceAddedEvent
  | DeviceConnectedEvent
  | DeviceDeletedEvent
  | DeviceDisconnectedEvent
  | DeviceResetEvent
  | DeviceUpdatedEvent
  | DeviceWifiCredentialsDeletedEvent
  | IOAlarmAddedEvent
  | IOAlarmDeletedEvent
  | IOAlarmUpdatedEvent
  | IOAutomationSetEvent
  | JobExecutionFailedEvent
  | IOManualStateSetEvent
  | ModuleConnectedEvent
  | ModuleDisconnectedEvent
  | UserJoinedEvent;

// this record forces devs to add the corresponding validator when a new organization event is added
export const validateOrganizationEventPayloadByOrganizationEventName: Record<OrganizationEventName, (payload: unknown) => void> = {
  [OrganizationEventName.DEVICE_ADDED]: validateDeviceAddedEventPayload,
  [OrganizationEventName.DEVICE_CONNECTED]: () => undefined,
  [OrganizationEventName.DEVICE_DELETED]: validateDeviceDeletedEventPayload,
  [OrganizationEventName.DEVICE_DISCONNECTED]: () => undefined,
  [OrganizationEventName.DEVICE_RESET]: validateDeviceResetEventPayload,
  [OrganizationEventName.DEVICE_SYNC]: validateDeviceSyncEventPayload,
  [OrganizationEventName.DEVICE_UPDATED]: validateDeviceUpdatedEventPayload,
  [OrganizationEventName.DEVICE_WIFI_CREDENTIALS_DELETED]: validateDeviceWifiCredentialsDeletedEventPayload,
  [OrganizationEventName.IO_ALARM_ADDED]: validateIOAlarmAddedOrUpdatedEventPayload,
  [OrganizationEventName.IO_ALARM_DELETED]: validateIOAlarmDeletedEventPayload,
  [OrganizationEventName.IO_ALARM_UPDATED]: validateIOAlarmAddedOrUpdatedEventPayload,
  [OrganizationEventName.IO_AUTOMATION_SET]: validateIOAutomationSetEventPayload,
  [OrganizationEventName.JOB_EXECUTION_FAILED]: validateJobExecutionFailedEventPayload,
  [OrganizationEventName.IO_MANUAL_STATE_SET]: validateIOManualStateSetEventPayload,
  [OrganizationEventName.MODULE_ADDED]: validateModuleAddedEventPayload,
  [OrganizationEventName.MODULE_DELETED]: validateModuleDeletedEventPayload,
  [OrganizationEventName.MODULE_CONNECTED]: () => undefined,
  [OrganizationEventName.MODULE_DISCONNECTED]: () => undefined,
  [OrganizationEventName.USER_JOINED]: validateUserJoinedEventPayload,
};
/**
 *
 * AlarmEvent
 *
 */

// we can reuse the alarm type to name our events
interface ThresholdAlarmEventPayload {
  value: number;
  threshold: number;
}

export interface AboveThresholdAlarmEvent extends BaseEvent {
  type: EventType.ALARM;
  name: AlarmType.ABOVE_THRESHOLD;
  organization: number;
  device: number;
  io: number;
  payload: ThresholdAlarmEventPayload;
}

export interface BelowThresholdAlarmEvent extends BaseEvent {
  type: EventType.ALARM;
  name: AlarmType.BELOW_THRESHOLD;
  organization: number;
  device: number;
  io: number;
  payload: ThresholdAlarmEventPayload;
}

interface RangeAlarmEventPayload {
  value: number;
  min: number;
  max: number;
}

export interface RangeAlarmEvent extends BaseEvent {
  type: EventType.ALARM;
  name: AlarmType.RANGE;
  organization: number;
  device: number;
  io: number;
  payload: RangeAlarmEventPayload;
}

export type AlarmEventPayload = ThresholdAlarmEventPayload | RangeAlarmEventPayload;
export type AlarmEvent = BelowThresholdAlarmEvent | AboveThresholdAlarmEvent | RangeAlarmEvent;

// this record forces devs to add the corresponding validator when a new alarm is added
export const validateAlarmEventPayloadByAlarmType: Record<AlarmType, (payload: unknown) => void> = {
  [AlarmType.ABOVE_THRESHOLD]: validateThresholdAlarmEventPayload,
  [AlarmType.BELOW_THRESHOLD]: validateThresholdAlarmEventPayload,
  [AlarmType.RANGE]: validateRangeAlarmEventPayload,
};

export const prepAlarmEventFromDevices = (event: AlarmEvent): AlarmEvent => {
  const { name, payload } = event;

  switch (name) {
    case AlarmType.ABOVE_THRESHOLD:
    case AlarmType.BELOW_THRESHOLD:
      return {
        ...event,
        payload: {
          ...payload,
          value: Math.round((payload.value / 100) * 100) / 100,
          threshold: Math.round((payload.threshold / 100) * 100) / 100,
        },
      };

    case AlarmType.RANGE:
      return {
        ...event,
        payload: {
          ...payload,
          value: Math.round((payload.value / 100) * 100) / 100,
          max: Math.round((payload.max / 100) * 100) / 100,
          min: Math.round((payload.min / 100) * 100) / 100,
        },
      };

    default:
      assertUnreachable('Invalid alarm name', name);
  }
};

/**
 * Builder
 * we don't need alarm builder because they're created by devices
 */

export class OrganizationEventBuilder {
  static createDeviceAddedEvent(organization: number, payload: DeviceAddedEventPayload): DeviceAddedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.DEVICE_ADDED,
      organization,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createDeviceConnectedEvent(organization: number, device: number): DeviceConnectedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.DEVICE_CONNECTED,
      organization,
      device,
      created: new Date().toISOString(),
    };
  }

  static createDeviceDeletedEvent(organization: number, payload: DeviceDeletedEventPayload): DeviceDeletedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.DEVICE_DELETED,
      organization,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createDeviceDisconnectedEvent(organization: number, device: number): DeviceDisconnectedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.DEVICE_DISCONNECTED,
      organization,
      device,
      created: new Date().toISOString(),
    };
  }

  static createDeviceResetEvent(organization: number, device: number, payload: DeviceResetEventPayload): DeviceResetEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.DEVICE_RESET,
      organization,
      device,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createDeviceSyncEvent(organization: number, device: number, payload: DeviceSyncEventPayload): DeviceSyncEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.DEVICE_SYNC,
      organization,
      device,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createDeviceUpdatedEvent(organization: number, device: number, payload: DeviceUpdatedEventPayload): DeviceUpdatedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.DEVICE_UPDATED,
      organization,
      device,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createDeviceWifiCredentialsDeletedEvent(
    organization: number,
    device: number,
    payload: DeviceWifiCredentialsDeletedEventPayload
  ): DeviceWifiCredentialsDeletedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.DEVICE_WIFI_CREDENTIALS_DELETED,
      organization,
      device,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createIOAlarmAddedEvent(
    organization: number,
    device: number,
    io: number,
    payload: IOAlarmAddedOrUpdatedEventPayload
  ): IOAlarmAddedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.IO_ALARM_ADDED,
      organization,
      device,
      io,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createIOAlarmDeletedEvent(
    organization: number,
    device: number,
    io: number,
    payload: IOAlarmDeletedEventPayload
  ): IOAlarmDeletedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.IO_ALARM_DELETED,
      organization,
      device,
      io,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createIOAlarmUpdatedEvent(
    organization: number,
    device: number,
    io: number,
    payload: IOAlarmAddedOrUpdatedEventPayload
  ): IOAlarmUpdatedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.IO_ALARM_UPDATED,
      organization,
      device,
      io,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createIOAutomationSetEvent(
    organization: number,
    device: number,
    io: number,
    payload: IOAutomationSetEventPayload
  ): IOAutomationSetEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.IO_AUTOMATION_SET,
      organization,
      device,
      io,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createJobExecutionFailedEvent(
    organization: number,
    device: number,
    io: number | null,
    payload: JobExecutionFailedEventPayload
  ): JobExecutionFailedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.JOB_EXECUTION_FAILED,
      organization,
      device,
      io,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createIOManualStateSetEvent(
    organization: number,
    device: number,
    io: number,
    payload: IOManualStateSetEventPayload
  ): IOManualStateSetEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.IO_MANUAL_STATE_SET,
      organization,
      device,
      io,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createModuleAddedEvent(organization: number, device: number, payload: ModuleAddedEventPayload): ModuleAddedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.MODULE_ADDED,
      organization,
      device,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createModuleDeletedEvent(organization: number, device: number, payload: ModuleDeletedEventPayload): ModuleDeletedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.MODULE_DELETED,
      organization,
      device,
      created: new Date().toISOString(),
      payload,
    };
  }

  // this is only used by the bot
  static createModuleConnectedEvent(organization: number, device: number, payload: ModuleConnectedEventPayload): ModuleConnectedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.MODULE_CONNECTED,
      organization,
      device,
      created: new Date().toISOString(),
      payload,
    };
  }

  // this is only used by the bot
  static createModuleDisconnectedEvent(
    organization: number,
    device: number,
    payload: ModuleDisconnectedEventPayload
  ): ModuleDisconnectedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.MODULE_DISCONNECTED,
      organization,
      device,
      created: new Date().toISOString(),
      payload,
    };
  }

  static createUserJoinedEvent(organization: number, payload: UserJoinedEventPayload): UserJoinedEvent {
    return {
      type: EventType.ORGANIZATION,
      name: OrganizationEventName.USER_JOINED,
      organization,
      created: new Date().toISOString(),
      payload,
    };
  }
}

/**
 *
 * validators
 *
 */
export function validateEventType(type: string): asserts type is EventType {
  if (!Object.values(EventType).includes(type as EventType)) {
    throw new Error(`Invalid event type: ${type}`);
  }
}

export function validateOrganizationEventName(name: string): asserts name is OrganizationEventName {
  if (!Object.values(OrganizationEventName).includes(name as OrganizationEventName)) {
    throw new Error(`Invalid organization event name: ${name}`);
  }
}

export function validateAlarmEventName(name: string): asserts name is AlarmType {
  if (!Object.values(AlarmType).includes(name as AlarmType)) {
    throw new Error(`Invalid alarm event name: ${name}`);
  }
}

/**
 * Payload
 */
export function validateDeviceAddedEventPayload(payload: unknown): asserts payload is DeviceAddedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'device');
  validateObjectKey(payload, 'author');

  const { device, author } = payload;

  validateNumber(device);
  validateString(author);
}

export function validateDeviceDeletedEventPayload(payload: unknown): asserts payload is DeviceDeletedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'deviceId');
  validateObjectKey(payload, 'deviceName');
  validateObjectKey(payload, 'author');

  const { deviceId, deviceName, author } = payload;

  validateNumber(deviceId);
  validateString(deviceName);
  validateString(author);
}

export function validateDeviceResetEventPayload(payload: unknown): asserts payload is DeviceResetEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'author');

  const { author } = payload;

  validateString(author);
}

export function validateDeviceUpdatedEventPayload(payload: unknown): asserts payload is DeviceUpdatedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'expectedVersion');
  validateObjectKey(payload, 'author');

  const { expectedVersion, author } = payload;

  validateNumber(expectedVersion);
  validateString(author);
}

export function validateDeviceSyncEventPayload(payload: unknown): asserts payload is DeviceSyncEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'author');

  const { author } = payload;

  validateString(author);
}

export function validateDeviceWifiCredentialsDeletedEventPayload(
  payload: unknown
): asserts payload is DeviceWifiCredentialsDeletedEventPayload {
  validateNonEmptyObject(payload);

  validateObjectKey(payload, 'author');

  const { author } = payload;

  validateString(author);
}

export function validateModuleAddedEventPayload(payload: unknown): asserts payload is ModuleAddedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'module');
  validateObjectKey(payload, 'author');

  const { module, author } = payload;

  validateNumber(module);
  validateString(author);
}

export function validateModuleDeletedEventPayload(payload: unknown): asserts payload is ModuleDeletedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'moduleName');
  validateObjectKey(payload, 'author');

  const { moduleName, author } = payload;

  validateString(moduleName);
  validateString(author);
}

export function validateModuleConnectedEventPayload(payload: unknown): asserts payload is ModuleConnectedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'module');

  const { module } = payload;

  validateNumber(module);
}

export function validateModuleDisconnectedEventPayload(payload: unknown): asserts payload is ModuleDisconnectedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'module');

  const { module } = payload;

  validateNumber(module);
}

export function validateUserJoinedEventPayload(payload: unknown): asserts payload is UserJoinedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'username');

  const { username } = payload;

  validateString(username);
}

export function validateIOAlarmAddedOrUpdatedEventPayload(payload: unknown): asserts payload is IOAlarmAddedOrUpdatedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'id');
  validateObjectKey(payload, 'raw');
  validateObjectKey(payload, 'author');

  const { id, raw, author } = payload;

  validateNumber(id);
  validateAlarm(raw);
  validateString(author);
}

export function validateIOAlarmDeletedEventPayload(payload: unknown): asserts payload is IOAlarmAddedOrUpdatedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'id');
  validateObjectKey(payload, 'type');
  validateObjectKey(payload, 'author');

  const { id, type, author } = payload;

  validateNumber(id);
  validateString(type);
  validateAlarmType(type);
  validateString(author);
}

export function validateIOAutomationSetEventPayload(payload: unknown): asserts payload is IOAutomationSetEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'raw');
  validateObjectKey(payload, 'automation');
  validateObjectKey(payload, 'author');

  const { raw, automation, author } = payload;

  validateMinifiedAutomation(raw);
  validateNumber(automation);
  validateString(author);
}

export function validateJobExecutionFailedEventPayload(payload: unknown): asserts payload is JobExecutionFailedEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'status');
  validateObjectKey(payload, 'action');
  validateObjectKey(payload, 'jobId');
  validateObjectKey(payload, 'author');

  const { status, action, jobId, author } = payload;

  validateString(status);
  validateJobExecutionStatus(status);
  validateString(author);
  validateString(action);
  validateJobAction(action);

  validateString(jobId);
}

export function validateIOManualStateSetEventPayload(payload: unknown): asserts payload is IOManualStateSetEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'state');
  validateObjectKey(payload, 'author');

  const { state, author } = payload;

  validateNumber(state);
  validateString(author);
}

// alarms
export function validateThresholdAlarmEventPayload(payload: unknown): asserts payload is ThresholdAlarmEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'value');
  validateObjectKey(payload, 'threshold');

  const { value, threshold } = payload;

  validateNumber(value);
  validateNumber(threshold);
}

export function validateRangeAlarmEventPayload(payload: unknown): asserts payload is RangeAlarmEventPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'value');
  validateObjectKey(payload, 'min');
  validateObjectKey(payload, 'max');

  const { value, min, max } = payload;

  validateNumber(value);
  validateNumber(min);
  validateNumber(max);
}
