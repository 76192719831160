export enum NotificationChannel {
  EMAIL = 'email',
  TELEGRAM = 'telegram',
  WHATSAPP = 'whatsapp',
}
export function validateNotificationChannel(channel: string): asserts channel is NotificationChannel {
  if (!Object.values(NotificationChannel).includes(channel as NotificationChannel)) {
    throw new Error('Invalid notification channel');
  }
}

export const AvailableNotifications = Object.values(NotificationChannel).filter((channel) => channel !== NotificationChannel.WHATSAPP);
