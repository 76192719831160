import React from 'react';
import { HStack, Icon, Text, Box, BoxProps } from '@chakra-ui/react';
import { RiCopyrightLine as CopyrightIcon } from 'react-icons/ri';
import { getEnvs } from '../../utils/getEnvs';
import { IconType } from 'react-icons';

const { release } = getEnvs();

export const Footer: React.FC<BoxProps> = (props) => {
  return (
    <Box px={2} {...props}>
      <HStack bg="bg500" rounded={'md'} px={5} py={2} borderBottomRadius={0} justifyContent={'space-between'} alignItems="center">
        <HStack alignItems="center">
          <HStack>
            <Icon as={CopyrightIcon as IconType} />
            <Text>{new Date().getFullYear()}</Text>
            <Text>Growcast</Text>
          </HStack>
        </HStack>
        <Text>{release}</Text>
      </HStack>
    </Box>
  );
};
