import React from 'react';
import { MQTTClientContext, MQTTConnection } from './MQTTClientContext';

export const useMQTTClient = <T extends MQTTConnection>(): T => {
  const client = React.useContext(MQTTClientContext) as T;

  validateClient(client);
  return client;
};

function validateClient(client: MQTTConnection | null): asserts client is MQTTConnection {
  if (client === null) {
    throw new Error('Attemping to use client out of its context');
  }
}
