import { CognitoUser, CognitoUserSession, AuthenticationDetails, UserData } from 'amazon-cognito-identity-js';
import { Locales } from '../i18n/utils';

export enum SessionState {
  BOOTING = 'booting',
  LOGGED_OUT = 'loggedOut',
  LOGGED_IN = 'loggedIn',
}

export interface SignUpParams {
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
  phoneNumber?: string;
  invite?: string;
}

export interface SignInParams {
  username: string;
  password: string;
}

export interface SignInResponse {
  totpRequired: boolean;
  cognitoUser: CognitoUser;
  newSession?: CognitoUserSession;
}

export interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
}
export interface ConfirmRegistrationParams {
  code: string;
}

export interface ForgotPasswordParams {
  username: string;
}

interface CodeDetails {
  Destination: string;
}
export interface ForgotPasswordResponse {
  CodeDeliveryDetails: CodeDetails;
}

export enum VerifiableAttribute {
  EMAIL = 'email',
  PHONE = 'phone_number',
}
export interface RequestAttributeVerificationParams {
  attributeName: VerifiableAttribute;
}

export interface ResetPasswordParams {
  verificationCode: string;
  newPassword: string;
}

export interface UpdateAttributeParams {
  attributeName: string;
  value: string;
}

export interface UpdateLocaleParams {
  locale: Locales;
}

export interface VerifyAttributeParams {
  attributeName: VerifiableAttribute;
  code: string;
}

export interface VerifyMFATokenParams {
  totpCode: string;
  friendlyDeviceName: string;
}

export interface SendMFATokenParams {
  confirmationCode: string;
}

/**
 * AWS Types file is wrong. The verify token fn doesn't
 * return a congnito session promise. It returns a promise
 * of this
 */
export interface SetUserMfaPreferenceResponse {
  Status: 'SUCCESS';
}

export interface VerifyMFATokenResponse {
  Status: 'SUCCESS';
}

export type SessionRefreshCallback = (newSession: CognitoUserSession) => void;

interface BaseSession {
  cleanSession: () => void;
  isBooting: boolean;
  refresh: (callback?: SessionRefreshCallback) => Promise<CognitoUserSession>;
  setCognitoAsyncAttrs: (user: UserData | null) => void;
  setCognitoUser: (user: CognitoUser | null) => void;
  setCognitoSession: (session: CognitoUserSession | null) => void;
  setTemporaryCredentials: (credentials: AuthenticationDetails | null) => void;
  temporaryCredentials: AuthenticationDetails | null;
}
export interface AuthenticatedSession extends BaseSession {
  asyncAttrs: UserData;
  isLoggedIn: true;
  session: CognitoUserSession;
  temporaryCredentials: null;
  user: CognitoUser;
}

export interface UnAuthenticatedSession extends BaseSession {
  asyncAttrs: null;
  isLoggedIn: false;
  session: null;
  temporaryCredentials: AuthenticationDetails | null;
  user: CognitoUser | null;
}

export type Session = AuthenticatedSession | UnAuthenticatedSession;

export function isValidVerifiableAttribute(attribute: string): attribute is VerifiableAttribute {
  return Object.values(VerifiableAttribute).includes(attribute as VerifiableAttribute);
}
