import { iot, mqtt5 } from 'aws-iot-device-sdk-v2/dist/browser';
import { getEnvs } from '../utils/getEnvs';
import { AWSCognitoCredentialsProvider } from './credentials';
import { once } from 'events';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import createDebugger from 'debug';
const debug = createDebugger('Growcast:MQTT5Client');

type AsyncTokenRefreshFn = () => Promise<CognitoUserSession>;
export class MQTT5Client {
  provider: AWSCognitoCredentialsProvider;
  userIdentifier: string;
  client: mqtt5.Mqtt5Client | null = null;
  isConnected = false;
  error: string | null = null;

  constructor(userIdentifier: string, session: CognitoUserSession, refreshSession: AsyncTokenRefreshFn) {
    const { region, identityPoolId, userPoolId } = getEnvs();

    const options = {
      region,
      identityPoolId,
      userPoolId,
      session,
      refreshSession,
      userIdentifier,
    };

    this.userIdentifier = userIdentifier;
    this.provider = new AWSCognitoCredentialsProvider(options);
  }

  async connect() {
    debug('trying to connect');
    await this.provider.refreshCredentials();

    const { region, mqttEndpoint } = getEnvs();
    const wsConfig: iot.WebsocketSigv4Config = {
      credentialsProvider: this.provider,
      region,
    };

    const builder: iot.AwsIotMqtt5ClientConfigBuilder = iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
      mqttEndpoint,
      wsConfig
    ).withConnectProperties({ keepAliveIntervalSeconds: 60 });

    const config = builder.build();
    this.client = new mqtt5.Mqtt5Client(config);

    this.client.on('error', (error) => {
      this.error = error.toString();

      debug('internal error', error);
      if (this.client) {
        this.client.removeAllListeners();
        this.client = null;
      }
    });

    const attemptingConnect = once(this.client, 'attemptingConnect');
    const connectionSuccess = once(this.client, 'connectionSuccess');

    this.client.start();

    debug('attemptingConnect');
    await attemptingConnect;

    await connectionSuccess;
    debug('connected');
  }

  onDisconnect(callback: mqtt5.DisconnectionEventListener) {
    if (this.client === null) {
      throw new Error('MQTTClient is not created');
    }

    debug('Setting disconnection callback');
    this.client.on('disconnection', callback);
  }

  async disconnect() {
    try {
      if (this.client === null) {
        return;
      }

      debug('disconneciton requested');
      this.client.removeAllListeners();

      const disconnection = once(this.client, 'disconnection');
      const stopped = once(this.client, 'stopped');

      this.client.stop();
      this.client.close();

      await disconnection;
      await stopped;

      // Dispose the credentials provider
      this.provider.dispose();
    } catch (e) {
      debug('Error disconnecting');
    } finally {
      this.client = null;
    }
  }
}
