import React from 'react';
import { ScaleFade, SlideFade } from '@chakra-ui/react';

interface AnimatedSwipeWrapperProps {
  offsetX?: string | number;
  offsetY?: string | number;
  children: React.ReactNode;
}

export const RightToLeftAnimation: React.FC<AnimatedSwipeWrapperProps> = (props) => {
  const { offsetX = '24ch', offsetY = 0 } = props;

  return (
    <SlideFade offsetX={offsetX} offsetY={offsetY} in={true}>
      {props.children}
    </SlideFade>
  );
};

export const LeftToRightAnimation: React.FC<AnimatedSwipeWrapperProps> = (props) => {
  const { offsetX = '24ch', offsetY = 0 } = props;

  return (
    <SlideFade offsetX={offsetX} offsetY={offsetY} in={true}>
      {props.children}
    </SlideFade>
  );
};

export const BottomToTopAnimation: React.FC<AnimatedSwipeWrapperProps> = (props) => {
  const { offsetX = 0, offsetY = '24ch' } = props;

  return (
    <SlideFade offsetX={offsetX} offsetY={offsetY} in={true}>
      {props.children}
    </SlideFade>
  );
};

interface AnimatedScaleWrapperProps {
  initialScale?: number;
  children: React.ReactNode;
}

export const ScaleAnimation: React.FC<AnimatedScaleWrapperProps> = (props) => {
  const { initialScale = 0.85 } = props;

  return (
    <ScaleFade initialScale={initialScale} in={true}>
      {props.children}
    </ScaleFade>
  );
};
