import { AuthenticatedSession, useSession } from '.';
import { SessionUser, parseCognitoPayload } from 'common';

export const useSessionUser = (): SessionUser => {
  const { session } = useSession<AuthenticatedSession>();

  const idToken = session.getIdToken();
  const payload = idToken.decodePayload();
  const user = parseCognitoPayload(payload);

  return user;
};
