import { useLocalStorage } from '../../utils/useLocalStorage';

const KEY = 'PENDING_SETUP_DEVICE_ID';

/* we use local storage because on device and organization setup, the browser loses conection to the internet,
 this allows to persist a state, helping users to continue the setup walkthrough despite losing conection  */

export const usePendingSetupDevice = () => {
  const [value, setPendingDeviceId, clearPendingDeviceId] = useLocalStorage<{ id: number }>(KEY);

  const setPendingSetupDevice = (id: number) => {
    setPendingDeviceId({ id });
  };

  const clearPendingSetupDevice = () => {
    clearPendingDeviceId();
  };

  return { setPendingSetupDevice, clearPendingSetupDevice, pendingDeviceId: value?.id ?? null };
};
