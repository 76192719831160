import { MessageDescriptor, defineMessages } from 'react-intl';
import { TRPCClientErrorLike } from '@trpc/client';
import { API } from '../../api/trpc';

export type APIErrorFormatter = (error: TRPCClientErrorLike<API>) => MessageDescriptor;

export const errorMessages = defineMessages({
  internalError: {
    id: 'ErrorViewer.internal_error',
    defaultMessage: 'No se pudo completar la operación, por favor intente nuevamente',
  },
  validationError: {
    id: 'ErrorViewer.validation_error',
    defaultMessage: 'No se pudo validar la operación, por favor revise los datos ingresados',
  },
  notFound: {
    id: 'ErrorViewer.not_found_error',
    defaultMessage: 'Lo siento, no pudimos encontrar lo que estas buscando.',
  },
  badRequest: {
    id: 'ErrorViewer.bad_request_error',
    defaultMessage: 'Lo siento, tu solicitud no pudo ser procesada. Por favor, revisa tu entrada.',
  },
  unauthorized: {
    id: 'ErrorViewer.unauthorized_error',
    defaultMessage: 'Lo siento, debes estar autenticado para realizar esta acción',
  },
  forbidden: {
    id: 'ErrorViewer.forbidden_error',
    defaultMessage: 'Lo siento, no tienes permiso para realizar esta acción.',
  },
});

export const defaultFormatter: APIErrorFormatter = (error) => {
  const { data } = error;

  const zodError = data?.zodError;
  if (zodError) {
    return errorMessages.validationError;
  }

  if (data?.httpStatus === 400) {
    return errorMessages.badRequest;
  }

  if (data?.httpStatus === 401) {
    return errorMessages.unauthorized;
  }

  if (data?.httpStatus === 403) {
    return errorMessages.forbidden;
  }

  if (data?.httpStatus === 404) {
    return errorMessages.notFound;
  }

  return errorMessages.internalError;
};
