import React from 'react';
import { SessionContext } from './context';
import { Session } from './session';

export const useSession = <T extends Session>(): T => {
  const session = React.useContext(SessionContext) as T;

  validateSession(session);
  return session;
};

function validateSession(session: Session | null): asserts session is Session {
  if (session === null) {
    throw new Error('Attemping to use session out of its context');
  }
}
