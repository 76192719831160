import { JobExecutionStatus } from 'common';
import { MessageDescriptor, defineMessages } from 'react-intl';

export const statusNameMessages = defineMessages<JobExecutionStatus, MessageDescriptor>({
  [JobExecutionStatus.CANCELED]: {
    id: 'JobExecutionStatus.canceled',
    defaultMessage: 'Cancelado',
  },
  [JobExecutionStatus.FAILED]: {
    id: 'JobExecutionStatus.failed',
    defaultMessage: 'Fallido',
  },
  [JobExecutionStatus.IN_PROGRESS]: {
    id: 'JobExecutionStatus.inProgress',
    defaultMessage: 'En progreso',
  },
  [JobExecutionStatus.QUEUED]: {
    id: 'JobExecutionStatus.queued',
    defaultMessage: 'En cola',
  },
  [JobExecutionStatus.REJECTED]: {
    id: 'JobExecutionStatus.rejected',
    defaultMessage: 'Rechazado',
  },
  [JobExecutionStatus.REMOVED]: {
    id: 'JobExecutionStatus.removed',
    defaultMessage: 'Eliminado',
  },
  [JobExecutionStatus.SUCCEEDED]: {
    id: 'JobExecutionStatus.succeeded',
    defaultMessage: 'Exitoso',
  },
  [JobExecutionStatus.TIMED_OUT]: {
    id: 'JobExecutionStatus.timedOut',
    defaultMessage: 'Expirado',
  },
});

export const statusDescriptionMessages = defineMessages<JobExecutionStatus, MessageDescriptor>({
  [JobExecutionStatus.CANCELED]: {
    id: 'JobExecutionStatus.canceled_description',
    defaultMessage: 'La tarea ha sido cancelado antes de completarse.',
  },
  [JobExecutionStatus.FAILED]: {
    id: 'JobExecutionStatus.failed_description',
    defaultMessage: 'La tarea no se completó debido a un error.',
  },
  [JobExecutionStatus.IN_PROGRESS]: {
    id: 'JobExecutionStatus.inProgress_description',
    defaultMessage: 'La tarea está actualmente en ejecución.',
  },
  [JobExecutionStatus.QUEUED]: {
    id: 'JobExecutionStatus.queued_description',
    defaultMessage: 'La tarea está en cola esperando ser procesado.',
  },
  [JobExecutionStatus.REJECTED]: {
    id: 'JobExecutionStatus.rejected_description',
    defaultMessage: 'La tarea ha sido rechazado y no será procesado.',
  },
  [JobExecutionStatus.REMOVED]: {
    id: 'JobExecutionStatus.removed_description',
    defaultMessage: 'La tarea ha sido eliminado del sistema.',
  },
  [JobExecutionStatus.SUCCEEDED]: {
    id: 'JobExecutionStatus.succeeded_description',
    defaultMessage: 'La tarea se ha completado con éxito.',
  },
  [JobExecutionStatus.TIMED_OUT]: {
    id: 'JobExecutionStatus.timedOut_description',
    defaultMessage: 'La tarea no se completó en el tiempo esperado y fue terminado.',
  },
});
