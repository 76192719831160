// useLocalStorage.ts
import { useState } from 'react';

export function useLocalStorage<T extends object>(key: string, initialValue?: T) {
  // Retrieve the existing value from local storage if it exists
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? (JSON.parse(storedValue) as T) : initialValue ?? null;

  // State to keep track of our value
  const [value, setValue] = useState<T | null>(initial);

  // Save to local storage

  const setStoredValue = (newValue: T | ((val: T) => T)) => {
    const valueToStore = newValue instanceof Function ? newValue(value as T) : newValue;

    localStorage.setItem(key, JSON.stringify(valueToStore));
    setValue(valueToStore);
  };

  // Remove from local storage
  const removeStoredValue = () => {
    localStorage.removeItem(key);
  };

  return [value, setStoredValue, removeStoredValue] as const;
}
