import { useSessionUser } from '../session';
import { ColorMode, themeConfig } from './config';

export const useUserThemeConfig = () => {
  const user = useSessionUser();

  if (!user.preferredColorMode) {
    return {
      ...themeConfig,
      useSystemColorMode: true,
    };
  }

  return {
    ...themeConfig,
    initialColorMode: user.preferredColorMode,
    useSystemColorMode: user.preferredColorMode === ColorMode.SYSTEM,
  };
};
