import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import createDebugger from 'debug';
const debug = createDebugger('Growcast:MQTTCredentials');
interface Options {
  userIdentifier?: string;
  region: string;
  identityPoolId: string;
  userPoolId: string;
  session: CognitoUserSession;
  refreshSession: () => Promise<CognitoUserSession>;
}

export interface MQTTCredentials {
  aws_access_id: string;
  aws_secret_key: string;
  aws_sts_token: string;
  aws_region: string;
}

// jwt expires in 1hs. lets refresh every 30min
const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000;
const ONE_HOUR_IN_MS = 2 * THIRTY_MINUTES_IN_MS;

export class AWSCognitoCredentialsProvider {
  private options: Options;
  private cachedCredentials?: MQTTCredentials;
  private sharedRefreshPromise: Promise<MQTTCredentials> | null = null;
  private credentialsExpirationDate: Date = new Date();
  private refreshIntervalId: NodeJS.Timeout;

  constructor(options: Options, expire_interval_in_ms?: number) {
    this.options = options;

    this.refreshIntervalId = setInterval(() => {
      void this.refreshCredentials();
    }, expire_interval_in_ms ?? THIRTY_MINUTES_IN_MS);
  }

  dispose() {
    clearInterval(this.refreshIntervalId);
    debug('Interval cleared');
  }

  getCredentials(): MQTTCredentials {
    return {
      aws_access_id: this.cachedCredentials?.aws_access_id ?? '',
      aws_secret_key: this.cachedCredentials?.aws_secret_key ?? '',
      aws_sts_token: this.cachedCredentials?.aws_sts_token ?? '',
      aws_region: this.options.region,
    };
  }

  /**
   * MQTTClient calls this method when it's created and we have no way to access
   * to the promise to wait before call the connect.
   * So, we have to call and await refreshCredentials in the beggining of connect
   * to ensure we have valid credentials.
   * This would result in 2 consecutive calls to refreshSession and credentials
   * generation if we don't correctly manage the situation.
   * Moving the actual refresh to an internal method allow us to consume the same
   * promise that the MQTTClient internally creates
   */
  async refreshCredentials() {
    debug('refreshing');

    if (this.credentialsExpirationDate.getTime() > Date.now() + THIRTY_MINUTES_IN_MS) {
      return;
    }

    if (this.sharedRefreshPromise) {
      // this is to manage 2 consecutive refresh before resolving the first one
      debug('resolving from pending promise');
      this.cachedCredentials = await this.sharedRefreshPromise;

      // Set expiration date to 1 hour from now
      this.credentialsExpirationDate = new Date(Date.now() + ONE_HOUR_IN_MS);
      this.sharedRefreshPromise = null;
      debug('done and clean');
      return;
    }

    debug('no pending promise');
    const nextIterationInSeconds = (Date.now() + THIRTY_MINUTES_IN_MS) / 1000;
    const sessionExpiration = this.options.session.getIdToken().getExpiration();
    const isSessionRefreshNeeded = sessionExpiration < Date.now() / 1000 || sessionExpiration <= nextIterationInSeconds;

    // no need to refresh yet
    if (isSessionRefreshNeeded) {
      debug('session refresh needed');
      const newSession = await this.options.refreshSession();

      debug('new token ', newSession.getIdToken().getJwtToken());
      this.options.session = newSession;
    }

    this.sharedRefreshPromise = this.askCredentialsToOurServer();

    this.cachedCredentials = await this.sharedRefreshPromise;
    this.credentialsExpirationDate = new Date(Date.now() + ONE_HOUR_IN_MS);
    this.sharedRefreshPromise = null;
  }

  async askCredentialsToOurServer() {
    debug('asking credentials to our server');
    const token = this.options.session.getIdToken().getJwtToken();

    const identityProviderName = `cognito-idp.${this.options.region}.amazonaws.com/${this.options.userPoolId}`;
    const options = {
      userIdentifier: this.options.userIdentifier,
      clientConfig: {
        region: this.options.region,
      },
      identityPoolId: this.options.identityPoolId,
      logins: {
        [identityProviderName]: token,
      },
    };

    const credentials = await fromCognitoIdentityPool(options)();
    debug('credentials done');
    return {
      aws_access_id: credentials.accessKeyId ?? '',
      aws_secret_key: credentials.secretAccessKey ?? '',
      aws_sts_token: credentials.sessionToken ?? '',
      aws_region: this.options.region,
    };
  }
}
