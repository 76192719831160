import React from 'react';
import { Heading, HeadingProps, Text, TextProps } from '@chakra-ui/react';
import { MessageDescriptor, PrimitiveType, useIntl } from 'react-intl';

/**
 *
 * Wrapper to show a quick link to a specific translation in the translation editor
 *
 */

interface TranslatedMessageProps extends TextProps {
  message: MessageDescriptor;
  values?: Record<string, PrimitiveType | React.ReactElement>;
}

export const TranslatedMessage: React.FC<TranslatedMessageProps> = (props) => {
  const { message, values, ...attrs } = props;

  const intl = useIntl();

  return <Text {...attrs}>{intl.formatMessage(message, values)}</Text>;
};

interface TranslatedHeadingProps extends HeadingProps {
  message: MessageDescriptor;
  values?: Record<string, PrimitiveType>;
}
export const TranslatedHeading: React.FC<TranslatedHeadingProps> = (props) => {
  const { message, values, ...attrs } = props;

  const intl = useIntl();
  return <Heading {...attrs}>{intl.formatMessage(message, values)}</Heading>;
};
