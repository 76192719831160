import { LoaderFunctionArgs } from 'react-router-dom';
import { APIUtils } from '../../../api/trpc';

export const identifiersViewerLoader = (utils: APIUtils) => async (args: LoaderFunctionArgs) => {
  const id = Number(args.params.id);

  const data = await utils.backoffice.identifier.get.ensureData({ id });

  return data;
};
