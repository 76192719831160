import React from 'react';
import { Container, Flex, Spacer } from '@chakra-ui/react';
import { Header } from './Header';
import { Navigate, Outlet } from 'react-router-dom';
import { Footer } from './Footer';
import { BottomToTopAnimation } from '..';
import { useSessionPermissions } from '../../session';
import { Role } from 'common';
import { Scenes, sitemap } from '../../routing';
import { MQTTClientProvider } from '../../broker/MQTTClientContext';
import { RealTimeListenerProvider } from '../../broker/RealTimeListenerContext';
import { JobExecutionProvider } from '../Jobs/JobExecutionContext';

export const UnauthTemplate: React.FC = () => {
  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Container maxW={'lg'} py={4} flexGrow={1} alignContent={'center'}>
        <Outlet />
      </Container>
      <BottomToTopAnimation>
        <Footer width={'full'} zIndex={2} />
      </BottomToTopAnimation>
    </Flex>
  );
};

export const SetupTemplate: React.FC = () => {
  return (
    <Flex direction="column" minH="100vh" bg={'appBg'} gap={2}>
      <Header position={'sticky'} width={'full'} top={0} left={0} isSidebarVisible />
      <Container maxW={'lg'} px={0} py={4}>
        <Outlet />
      </Container>
      <Spacer />
      <BottomToTopAnimation>
        <Footer width={'full'} zIndex={2} />
      </BottomToTopAnimation>
    </Flex>
  );
};

export const FullAuthTemplate: React.FC = () => {
  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'} gap={2}>
      <Header position={'sticky'} width={'full'} top={0} left={0} isSidebarVisible />
      <Container maxW={'container.xl'}>
        <MQTTClientProvider>
          <RealTimeListenerProvider>
            <JobExecutionProvider>
              <Outlet />
            </JobExecutionProvider>
          </RealTimeListenerProvider>
        </MQTTClientProvider>
      </Container>

      <Spacer />

      <Footer width={'full'} zIndex={2} />
    </Flex>
  );
};

export const AdminTemplate: React.FC = () => {
  const permissions = useSessionPermissions();

  if (!permissions.hasRole(Role.OWNER)) {
    return <Navigate to={sitemap[Scenes.MY_CULTIVATION].path} />;
  }

  return <FullAuthTemplate />;
};

export const SmallAuthTemplate: React.FC = () => {
  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'} gap={2}>
      <Header position={'sticky'} width={'full'} top={0} left={0} isSidebarVisible />
      <Container maxW={'lg'} mx="auto" px={0} py={2}>
        <Outlet />
      </Container>
    </Flex>
  );
};
