import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Skeleton,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { LogsSkeleton } from '../../components/CultivationLogs/LogsSkeleton';
import { Scenes, sitemap } from '../sitemap';

export const AuthFallbackElement = () => {
  const location = useLocation();

  const isMobile = useBreakpointValue({ base: true, md: true, lg: false });

  const displayLogs =
    location.pathname.startsWith(sitemap[Scenes.MY_CULTIVATION].path) ||
    (location.pathname.startsWith(sitemap[Scenes.DEVICES].path) && location.pathname.split('/').length === 3); // ensures it's only display on /devices/99 not the main /devices route or the children /devices/99/modules/99

  const hasLogs = !isMobile && displayLogs;

  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'} gap={2} pt={'64px'}>
      <Container maxW={'container.xl'}>
        <Grid templateColumns="repeat(12, 1fr)" gap={3}>
          <GridItem colStart={hasLogs ? undefined : { lg: 3 }} colSpan={{ base: 12, md: 12, lg: 8 }}>
            <Flex direction={'column'} gap={2}>
              <Skeleton width={['50%', '30%']} endColor={'bg500'} startColor="bg100" height={12} borderRadius={'md'} />
              <Card>
                <CardBody>
                  <VStack spacing={12} alignItems={'start'} width={'100%'}>
                    <Flex direction={['column', 'row']} width={'100%'} gap={4}>
                      <Skeleton width={'150px'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                      <Skeleton width={'150px'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                      <Skeleton width={'150px'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                    </Flex>

                    <Flex direction={'column'} gap={4} width={'100%'}>
                      <SimpleGrid minChildWidth={'210px'} gap={4} width={'100%'}>
                        <Skeleton endColor={'bg500'} startColor="bg100" height={140} borderRadius={'md'} />
                        <Skeleton endColor={'bg500'} startColor="bg100" height={140} borderRadius={'md'} />
                        <Skeleton endColor={'bg500'} startColor="bg100" height={140} borderRadius={'md'} />
                      </SimpleGrid>

                      <Skeleton ml={'auto'} width={'120px'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                    </Flex>

                    <Skeleton width={'200px'} endColor={'bg500'} startColor="bg100" height={10} borderRadius={'md'} />
                  </VStack>
                </CardBody>
              </Card>
            </Flex>
          </GridItem>
          {hasLogs && (
            <GridItem colSpan={{ base: 0, md: 0, lg: 4 }} pt={14}>
              <LogsSkeleton />
            </GridItem>
          )}
        </Grid>
      </Container>
    </Flex>
  );
};

export const SetupFallbackElement = () => {
  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Container maxW={'lg'} py={4} px={0}>
        <Flex direction={'column'} gap={2}>
          <Card>
            <CardHeader>
              <Skeleton width={'200px'} height={12} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
            </CardHeader>
            <CardBody>
              <VStack spacing={12} alignItems={'start'} width={'100%'}>
                <Flex direction={'column'} width={'100%'} gap={4}>
                  <Skeleton width={'100%'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                  <Skeleton width={'100%'} height={16} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                  <Skeleton width={'100%'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                </Flex>
                <Flex direction={'row'} width={'100%'} justifyContent={'space-between'} gap={4} my={4}>
                  <Skeleton width={'100px'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                  <Skeleton width={'100px'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                </Flex>
              </VStack>
            </CardBody>
          </Card>
        </Flex>
      </Container>
    </Flex>
  );
};
