import { Alarm, AlarmType, validateAlarm, validateAlarmType } from './alarms';
import { MinifiedAutomation, validateMinifiedAutomation } from './automations';
import { ModuleOutputForDevices, SyncOutputForDevices, moduleOutputForDevices, syncOutputForDevices } from './device-setup';
import { assertUnreachable, validateNonEmptyObject, validateNumber, validateObjectKey, validateString } from './validations';

export enum JobAction {
  ADD_ALARM = 'addAlarm',
  ADD_MODULE = 'addModule',
  DELETE_ALARM = 'deleteAlarm',
  DELETE_DEVICE = 'deleteDevice',
  DELETE_MODULE = 'deleteModule',
  DELETE_WIFI_CREDENTIALS = 'deleteWifiCredentials',
  RESET = 'reset',
  SET_AUTOMATION = 'setAutomation',
  SET_MANUAL_STATE = 'setManualState',
  SYNC = 'sync',
  UPDATE_ALARM = 'updateAlarm',
  UPDATE_FIRMWARE = 'updateFirmware',
}

interface BaseJob {
  organization: number;
  device: number;
  action: JobAction;
  author: string;
  payload: unknown;
}

/**
 * Add alarm
 * Add a new alarm for a specific IO
 */
export interface AddAlarmJobPayload {
  io: number;
  id: number;
  raw: Alarm;
}

export interface AddAlarmJob extends BaseJob {
  action: JobAction.ADD_ALARM;
  payload: AddAlarmJobPayload;
}

/**
 * Add module
 * Add a new module for a specific device
 */
export type AddModuleJobPayload = ModuleOutputForDevices;

export interface AddModuleJob extends BaseJob {
  action: JobAction.ADD_MODULE;
  payload: AddModuleJobPayload;
}

/**
 * Delete alarm
 * Delete an alarm from a specific IO
 */
export interface DeleteAlarmJobPayload {
  io: number;
  id: number;
  type: AlarmType;
}

export interface DeleteAlarmJob extends BaseJob {
  action: JobAction.DELETE_ALARM;
  payload: DeleteAlarmJobPayload;
}

/**
 * Delete device
 * Delete a device with all modules
 */
export interface DeleteDeviceJob extends BaseJob {
  action: JobAction.DELETE_DEVICE;
  payload: null;
}

/**
 * Delete module
 * Delete a module with all io
 */
export interface DeleteModuleJobPayload {
  id: number;
}

export interface DeleteModuleJob extends BaseJob {
  action: JobAction.DELETE_MODULE;
  payload: DeleteModuleJobPayload;
}

/**
 * Delete wifi credentials
 * Clear connection credentials
 */
export interface DeleteWifiCredentialsJob extends BaseJob {
  action: JobAction.DELETE_WIFI_CREDENTIALS;
  payload: null;
}

/**
 * Reset
 * Request for a reset from the app
 */
export interface ResetJob extends BaseJob {
  action: JobAction.RESET;
  payload: null;
}

/**
 * Update firmware
 * Request a specific firware version update
 */
export interface UpdateFirmwareJobPayload {
  expectedVersion: number;
}
export interface UpdateFirmwareJob extends BaseJob {
  action: JobAction.UPDATE_FIRMWARE;
  payload: UpdateFirmwareJobPayload;
}

/**
 * Set automation
 * Configure an automation for a specific IO
 */
export interface SetAutomationJobPayload {
  io: number;
  id: number;
  raw: MinifiedAutomation;
}
export interface SetAutomationJob extends BaseJob {
  action: JobAction.SET_AUTOMATION;
  payload: SetAutomationJobPayload;
}

/**
 * Set manual state
 * Configure manual state for a specific IO
 */
export interface SetManualStateJobPayload {
  io: number;
  state: number;
}

export interface SetManualStateJob extends BaseJob {
  action: JobAction.SET_MANUAL_STATE;
  payload: SetManualStateJobPayload;
}

/**
 * Sync
 * Send device information to be sync like timezone and IOs or modules name
 */
export type SyncJobPayload = SyncOutputForDevices;

export interface SyncJob extends BaseJob {
  action: JobAction.SYNC;
  payload: SyncJobPayload;
}

/**
 * Update alarm
 * Update the content of an alarm for a specific IO
 */
export interface UpdateAlarmJobPayload {
  io: number;
  id: number;
  raw: Alarm;
}

export interface UpdateAlarmJob extends BaseJob {
  action: JobAction.UPDATE_ALARM;
  payload: UpdateAlarmJobPayload;
}
/**
 * This represents a job document
 */

export type JobDocument =
  | AddAlarmJob
  | AddModuleJob
  | DeleteAlarmJob
  | DeleteDeviceJob
  | DeleteModuleJob
  | DeleteWifiCredentialsJob
  | ResetJob
  | UpdateFirmwareJob
  | SetAutomationJob
  | SetManualStateJob
  | SyncJob
  | UpdateAlarmJob;

/**
 * Devices need reference values to parsed to hundredths in alarms
 */
export const prepAlarmForDevices = (alarm: Alarm): Alarm => {
  const { type, payload } = alarm;

  switch (type) {
    case AlarmType.ABOVE_THRESHOLD:
    case AlarmType.BELOW_THRESHOLD:
      return {
        type,
        payload: {
          threshold: payload.threshold * 100,
        },
      };

    case AlarmType.RANGE:
      return {
        type,
        payload: {
          min: payload.min * 100,
          max: payload.max * 100,
        },
      };
    default:
      assertUnreachable('invalid alarm type', type);
  }
};

export class JobDocumentBuilder {
  static createAddAlarmJob(organization: number, device: number, author: string, payload: AddAlarmJobPayload): AddAlarmJob {
    return {
      organization,
      device,
      action: JobAction.ADD_ALARM,
      author,
      payload: {
        ...payload,
        raw: prepAlarmForDevices(payload.raw),
      },
    };
  }

  static createAddModuleJob(organization: number, device: number, author: string, payload: AddModuleJobPayload): AddModuleJob {
    return {
      organization,
      device,
      action: JobAction.ADD_MODULE,
      author,
      payload,
    };
  }

  static createDeleteAlarmJob(organization: number, device: number, author: string, payload: DeleteAlarmJobPayload): DeleteAlarmJob {
    return {
      organization,
      device,
      action: JobAction.DELETE_ALARM,
      author,
      payload,
    };
  }

  static createDeleteDeviceJob(organization: number, device: number, author: string): DeleteDeviceJob {
    return {
      organization,
      device,
      action: JobAction.DELETE_DEVICE,
      author,
      payload: null,
    };
  }

  static createDeleteModuleJob(organization: number, device: number, author: string, payload: DeleteModuleJobPayload): DeleteModuleJob {
    return {
      organization,
      device,
      action: JobAction.DELETE_MODULE,
      author,
      payload,
    };
  }

  static createDeleteWifiCredentialsJob(organization: number, device: number, author: string): DeleteWifiCredentialsJob {
    return {
      organization,
      device,
      action: JobAction.DELETE_WIFI_CREDENTIALS,
      author,
      payload: null,
    };
  }

  static createResetJob(organization: number, device: number, author: string): ResetJob {
    return {
      organization,
      device,
      action: JobAction.RESET,
      author,
      payload: null,
    };
  }

  static createSetAutomationJob(organization: number, device: number, author: string, payload: SetAutomationJobPayload): SetAutomationJob {
    return {
      organization,
      device,
      action: JobAction.SET_AUTOMATION,
      author,
      payload,
    };
  }

  static createSetManualStateJob(
    organization: number,
    device: number,
    author: string,
    payload: SetManualStateJobPayload
  ): SetManualStateJob {
    return {
      organization,
      device,
      action: JobAction.SET_MANUAL_STATE,
      author,
      payload: {
        ...payload,
        state: payload.state * 100,
      },
    };
  }

  static createSyncJob(organization: number, device: number, author: string, payload: SyncJobPayload): SyncJob {
    return {
      organization,
      device,
      action: JobAction.SYNC,
      author,
      payload,
    };
  }

  static createUpdateAlarmJob(organization: number, device: number, author: string, payload: UpdateAlarmJobPayload): UpdateAlarmJob {
    return {
      organization,
      device,
      action: JobAction.UPDATE_ALARM,
      author,
      payload: {
        ...payload,
        raw: prepAlarmForDevices(payload.raw),
      },
    };
  }

  static createUpdateFirmwareJob(
    organization: number,
    device: number,
    author: string,
    payload: UpdateFirmwareJobPayload
  ): UpdateFirmwareJob {
    return {
      organization,
      device,
      action: JobAction.UPDATE_FIRMWARE,
      author,
      payload,
    };
  }
}

// validations & assertions
export function isValidJobAction(action: string): action is JobAction {
  return Object.values(JobAction).includes(action as JobAction);
}

export function validateJobAction(action: string): asserts action is JobAction {
  if (!isValidJobAction(action)) {
    throw new Error(`Invalid job action: ${action}`);
  }
}

export function validateAddAlarmJobPayload(payload: unknown): asserts payload is AddAlarmJobPayload {
  validateNonEmptyObject(payload);

  validateObjectKey(payload, 'io');
  validateObjectKey(payload, 'id');
  validateObjectKey(payload, 'raw');

  const { io, id, raw } = payload;

  validateNumber(io);
  validateNumber(id);
  validateAlarm(raw);
}

export function validateAddModuleJobPayload(payload: unknown): asserts payload is AddModuleJobPayload {
  moduleOutputForDevices.parse(payload);
}

export function validateDeleteAlarmJobPayload(payload: unknown): asserts payload is DeleteAlarmJobPayload {
  validateNonEmptyObject(payload);

  validateObjectKey(payload, 'io');
  validateObjectKey(payload, 'id');

  const { io, id, type } = payload;

  validateNumber(io);
  validateNumber(id);

  validateString(type);
  validateAlarmType(type);
}

export function validateDeleteModuleJobPayload(payload: unknown): asserts payload is DeleteModuleJobPayload {
  validateNonEmptyObject(payload);

  validateObjectKey(payload, 'id');

  const { id } = payload;

  validateNumber(id);
}

export function validateSetAutomationJobPayload(payload: unknown): asserts payload is SetAutomationJobPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'io');
  validateObjectKey(payload, 'id');
  validateObjectKey(payload, 'raw');

  const { io, id, raw } = payload;

  validateNumber(io);
  validateNumber(id);
  validateMinifiedAutomation(raw);
}

export function validateSetManualStateJobPayload(payload: unknown): asserts payload is SetManualStateJobPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'io');
  validateObjectKey(payload, 'state');

  const { io, state } = payload;

  validateNumber(io);
  validateNumber(state);
}

export function validateSyncJobPayload(payload: unknown): asserts payload is SyncJobPayload {
  syncOutputForDevices.parse(payload);
}

export function validateUpdateAlarmJobPayload(payload: unknown): asserts payload is UpdateAlarmJobPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'io');
  validateObjectKey(payload, 'id');
  validateObjectKey(payload, 'raw');

  const { io, raw, id } = payload;

  validateNumber(io);
  validateNumber(id);
  validateAlarm(raw);
}

export function validateUpdateFirmwareJobPayload(payload: unknown): asserts payload is UpdateFirmwareJobPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'expectedVersion');

  const { expectedVersion } = payload;

  validateNumber(expectedVersion);
}

export const validatePayloadByJobAction: Record<JobAction, (payload: unknown) => void> = {
  [JobAction.ADD_ALARM]: validateAddAlarmJobPayload,
  [JobAction.ADD_MODULE]: validateAddModuleJobPayload,
  [JobAction.DELETE_ALARM]: validateDeleteAlarmJobPayload,
  [JobAction.DELETE_DEVICE]: () => undefined,
  [JobAction.DELETE_MODULE]: validateDeleteModuleJobPayload,
  [JobAction.DELETE_WIFI_CREDENTIALS]: () => undefined,
  [JobAction.RESET]: () => undefined,
  [JobAction.SET_AUTOMATION]: validateSetAutomationJobPayload,
  [JobAction.SET_MANUAL_STATE]: validateSetManualStateJobPayload,
  [JobAction.SYNC]: validateSyncJobPayload,
  [JobAction.UPDATE_ALARM]: validateUpdateAlarmJobPayload,
  [JobAction.UPDATE_FIRMWARE]: validateUpdateFirmwareJobPayload,
};

export function validateJobDocument(job: unknown): asserts job is JobDocument {
  validateNonEmptyObject(job);

  validateObjectKey(job, 'organization');
  validateObjectKey(job, 'device');
  validateObjectKey(job, 'action');
  validateObjectKey(job, 'payload');
  validateObjectKey(job, 'author');

  const { action, author, payload } = job;

  validateString(action);
  validateJobAction(action);
  validateString(author);

  const validatePayload = validatePayloadByJobAction[action];
  validatePayload(payload);
}

/**
 * This is copied from aws. we need it here to be able to create validators that we'll use
 * in several places
 */
export enum JobExecutionStatus {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
  REJECTED = 'REJECTED',
  REMOVED = 'REMOVED',
  CANCELED = 'CANCELED',
}

export function validateJobExecutionStatus(status: string): asserts status is JobExecutionStatus {
  if (!Object.values(JobExecutionStatus).includes(status as JobExecutionStatus)) {
    throw new Error(`Invalid job execution status: ${status}`);
  }
}
