import React from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Icon,
  Image,
  HStack,
  Text,
  DrawerOverlay,
  Divider,
  VStack,
  Flex,
  Spacer,
  StackProps,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MessageDescriptor } from 'react-intl';
import Logo from '../../assets/logos/isotipo.png';
import { Scenes, sitemap } from '../../routing';
import { TranslatedMessage } from '../../i18n';
import { IconType } from 'react-icons';

import {
  FaHardDrive as DeviceIcon,
  FaBuilding as OrganizationIcon,
  FaBuildingLock as OrganizationSettingsIcon,
  FaLock as AdminIcon,
  FaBookBookmark as CultivationRecordsIcon,
  FaIndustry as ZoneTypeIcon,
  FaUserGear as BackofficeIcon,
  FaUserCheck as ConfirmationIcon,
  FaRobot as ControlIcon,
  FaChartArea as AnalyticsIcon,
  FaHeadset as SupportIcon,
} from 'react-icons/fa6';
import { useSessionPermissions } from '../../session';
import { Role } from 'common';
import { commonScenesMessages } from '../../i18n/commonMessages';

interface AppDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>;
}

export const AppDrawer: React.FC<AppDrawerProps> = (props) => {
  const { buttonRef, isOpen, onClose } = props;
  const permissions = useSessionPermissions();
  return (
    <Drawer
      finalFocusRef={buttonRef}
      placement={'left'}
      onClose={onClose}
      isOpen={isOpen}
      returnFocusOnClose={false}
      onOverlayClick={onClose}
    >
      <DrawerOverlay />
      <DrawerContent bg={'bg500'}>
        <DrawerHeader px={3} borderBottomWidth="1px" borderColor="borderColor">
          <VStack w={'100%'} spacing={4}>
            <Flex w={'100%'}>
              <HStack>
                <Image src={Logo} alt="logo" maxH={'42px'} />
                <Text>Growcast</Text>
              </HStack>
              <Spacer />
              <DrawerCloseButton top={0} right={0} h={'auto'} position={'relative'} />
            </Flex>
          </VStack>
        </DrawerHeader>
        <DrawerBody px={2} borderColor="borderColor">
          <Flex direction={'column'} height={'100%'}>
            <RegularSection />
            <Spacer />

            {/* TODO: make it accessible to everyone */}
            {permissions.hasRole(Role.OWNER) && (
              <DrawerItem navigateTo={sitemap[Scenes.SUPPORT].path} label={commonScenesMessages.support} icon={SupportIcon} />
            )}
            <GlobalOwnerSection />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const RegularSection: React.VFC = () => {
  const permissions = useSessionPermissions();

  if (permissions.listOrganizations().length === 0) {
    return (
      <DrawerItem navigateTo={sitemap[Scenes.SETUP].children.invite.path} label={commonScenesMessages.invite} icon={ConfirmationIcon} />
    );
  }

  return (
    <>
      <DrawerItem navigateTo={sitemap[Scenes.MY_CULTIVATION].path} label={commonScenesMessages.myCultivation} icon={ZoneTypeIcon} />
      <DrawerItem navigateTo={sitemap[Scenes.DEVICES].path} label={commonScenesMessages.devices} icon={DeviceIcon} />
      <DrawerItem
        navigateTo={sitemap[Scenes.CULTIVATION_LOGS].path}
        label={commonScenesMessages.cultivationLogs}
        icon={CultivationRecordsIcon}
      />

      <DrawerItem navigateTo={sitemap[Scenes.AUTOMATIONS].path} label={commonScenesMessages.automations} icon={ControlIcon} />
      <DrawerDivider icon={OrganizationSettingsIcon} />

      <VStack width={'100%'}>
        <DrawerItem navigateTo={sitemap[Scenes.ANALYTICS].path} label={commonScenesMessages.analytics} icon={AnalyticsIcon} />
        <DrawerItem navigateTo={sitemap[Scenes.ORGANIZATION].path} label={commonScenesMessages.organization} icon={OrganizationIcon} />
      </VStack>
    </>
  );
};

const GlobalOwnerSection: React.VFC = () => {
  const permissions = useSessionPermissions();

  if (!permissions.hasRole(Role.OWNER)) {
    return null;
  }

  return (
    <>
      <DrawerDivider icon={AdminIcon} />

      <DrawerItem navigateTo={sitemap[Scenes.BACKOFFICE].path} label={commonScenesMessages.backoffice} icon={BackofficeIcon} />
    </>
  );
};

interface DrawerItemProps {
  navigateTo: string;
  label: MessageDescriptor;
  icon?: IconType;
}

export const DrawerItem: React.FC<DrawerItemProps> = (props) => {
  const { navigateTo, label, icon } = props;
  const navigate = useNavigate();

  const onClick = () => {
    navigate(navigateTo);
  };

  return (
    <Button gap={4} onClick={onClick} variant={'ghost'} w={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
      <Icon as={icon} />
      <TranslatedMessage message={label} />
    </Button>
  );
};

interface DrawerDividerProps extends StackProps {
  icon: IconType;
}

const DrawerDivider: React.FC<DrawerDividerProps> = (props) => {
  const { icon, ...otherProps } = props;

  return (
    <HStack alignItems={'center'} px={5} my={4} spacing={4} {...otherProps}>
      <Icon as={icon} color={'fontColorLight'} fontSize={'.8rem'} />
      <Divider borderColor={'borderColor'} />
    </HStack>
  );
};
