import React from 'react';
import { ChakraProvider, extendTheme, Theme, useColorMode } from '@chakra-ui/react';
import { useSession } from '../session';
import { useUserThemeConfig } from './useThemeConfig';
import { ColorMode, isValidColorMode, themeConfig } from '.';
import { ThemeConfig } from '@chakra-ui/react';
import { updateMetaThemeContent } from './utils';

interface Props {
  children?: React.ReactNode;
}

export type UserTheme = ReturnType<typeof useUserThemeConfig> & ThemeConfig;
export type AppTheme = Theme & UserTheme;

const AuthThemeProvider: React.FC<Props> = (props) => {
  const userConfig = useUserThemeConfig();
  const theme = extendTheme(userConfig);

  return (
    <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'bottom-right' } }}>
      <>
        {isValidColorMode(userConfig.initialColorMode) && <SyncColorMode newMode={userConfig.initialColorMode} />}
        {props.children}
      </>
    </ChakraProvider>
  );
};

export const ThemeProvider: React.FC<Props> = (props) => {
  const { isLoggedIn } = useSession();

  if (isLoggedIn) {
    return <AuthThemeProvider>{props.children}</AuthThemeProvider>;
  }

  const theme = extendTheme(themeConfig);
  return (
    <ChakraProvider theme={theme}>
      <>
        {isValidColorMode(themeConfig.initialColorMode) && <SyncColorMode newMode={themeConfig.initialColorMode} />}
        {props.children}
      </>
    </ChakraProvider>
  );
};

/**
 * This is the only way I found to sync correctly the color mode
 * with the actual value in session. if no preference, it takes the one from the system
 */
interface SyncColorModeProps {
  newMode: ColorMode;
}
const SyncColorMode: React.FC<SyncColorModeProps> = (props) => {
  const { newMode } = props;

  const { setColorMode } = useColorMode();
  const currentMode = localStorage.getItem('chakra-ui-color-mode');

  React.useEffect(() => {
    if (newMode !== currentMode) {
      const timeout = setTimeout(() => {
        updateMetaThemeContent(newMode);
        setColorMode(newMode);

        localStorage.setItem('chakra-ui-color-mode', newMode);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [currentMode, newMode, setColorMode]);

  return null;
};
