import React from 'react';
import { CognitoUserSession, CognitoUser, AuthenticationDetails, ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import { Session, SessionState } from './session';
import { getUserPool } from './cognito';
import createDebugger from 'debug';

const debug = createDebugger('Growcast:SessionProvider');
interface Props {
  children?: React.ReactNode;
}

export const SessionContext = React.createContext<Session | null>(null);
export const SessionProvider: React.FC<Props> = (props) => {
  const pool = getUserPool();

  const [user, setUser] = React.useState<CognitoUser | null>(pool.getCurrentUser());
  const [session, setSession] = React.useState<CognitoUserSession | null>(null);
  const [sessionState, setState] = React.useState<SessionState>(SessionState.BOOTING);
  const [credentials, setCredentials] = React.useState<AuthenticationDetails | null>(null);

  /**
   * Some user details are not in the decoded token and they should be fetch
   * manually. So, we would also want to keep them here to avoid fetching them every time we need them
   */
  const [asyncAttrs, setAsyncAttrs] = React.useState<ICognitoUserAttributeData | null>(null);

  const clean = React.useCallback(() => {
    setSession(null);
    setUser(null);
    setCredentials(null);
    setSessionState(SessionState.LOGGED_OUT);
  }, []);

  const getCognitoSession = React.useCallback(async (): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
      if (user !== null) {
        user.getSession((error: Error | null, session: CognitoUserSession | null) => {
          if (error !== null) {
            return reject(error);
          }

          if (session !== null) {
            return resolve(session);
          }
        });
      } else {
        return reject(new Error('Never authenticated'));
      }
    });
  }, [user]);

  const refreshCognitoSession = React.useCallback(
    async (newSession: CognitoUserSession): Promise<CognitoUserSession> => {
      return new Promise((resolve, reject) => {
        if (user !== null) {
          const refreshToken = newSession.getRefreshToken();

          user.refreshSession(refreshToken, (error: Error | null, session: CognitoUserSession | null) => {
            if (error !== null) {
              return reject(error);
            }

            if (session !== null) {
              return resolve(session);
            }
          });
        } else {
          return reject(new Error('Tried to refresh but never authenticated'));
        }
      });
    },
    [user]
  );

  /**
   * Refreshes a user's token with cognito. Useful for updating the UI when
   * there are permissions or other token changes. Returns the current cognito
   * session.
   */
  const refresh = React.useCallback(async (): Promise<CognitoUserSession> => {
    debug('refreshing');
    const initialSession = await getCognitoSession();
    const currentSession = await refreshCognitoSession(initialSession);

    if (currentSession.isValid()) {
      setState(SessionState.LOGGED_IN);
      setSession(currentSession);

      return currentSession;
    }

    throw new Error('invalid session');
  }, [getCognitoSession, refreshCognitoSession]);

  const setCognitoAsyncAttrs = React.useCallback((attrs: ICognitoUserAttributeData | null) => {
    setAsyncAttrs(attrs);
  }, []);

  const setCognitoSession = React.useCallback((session: CognitoUserSession | null) => {
    setSession(session);

    if (session?.isValid()) {
      setSessionState(SessionState.LOGGED_IN);
    } else {
      setSessionState(SessionState.LOGGED_OUT);
    }
  }, []);

  const setCognitoUser = React.useCallback((user: CognitoUser | null) => {
    setUser(user);
  }, []);

  const setSessionState = (state: SessionState) => {
    setState(state);
  };

  const setTemporaryCredentials = (credentials: AuthenticationDetails) => {
    setCredentials(credentials);
  };

  /**
   * Refresh the existing section after mounting the context if exist
   */
  React.useEffect(() => {
    if (sessionState === SessionState.BOOTING) {
      refresh().catch(() => clean());
    }
  }, [refresh, sessionState, clean]);

  const contextValue = {
    asyncAttrs,
    cleanSession: clean,
    isBooting: sessionState === SessionState.BOOTING,
    isLoggedIn: sessionState === SessionState.LOGGED_IN,
    refresh,
    session,
    setCognitoAsyncAttrs,
    setCognitoSession,
    setCognitoUser,
    setSessionState,
    setTemporaryCredentials,
    temporaryCredentials: credentials,
    user,
  };

  return <SessionContext.Provider value={contextValue as Session}>{props.children}</SessionContext.Provider>;
};
