import { mqtt5 } from 'aws-iot-device-sdk-v2';
import {
  EventType,
  extractDeviceIdFromClientId,
  OrganizationEventName,
  validateArray,
  validateNonEmptyObject,
  validateNumber,
  validateObjectKey,
  validateString,
  ModuleConnectedEvent,
  ModuleDisconnectedEvent,
} from 'common';
import createDebugger from 'debug';

const debug = createDebugger('Growcast:ParsingEvents');

export interface ValidStateMessageIOItem {
  id: number;
  value: number;
}

export interface ValidStateMessage {
  organization: number;
  device: number;
  ios: Array<ValidStateMessageIOItem>;
  created: number;
}

export function validateMessagePayload(value: unknown): asserts value is ValidStateMessage {
  validateNonEmptyObject(value);
  validateObjectKey(value, 'ios');
  validateObjectKey(value, 'device');
  validateObjectKey(value, 'organization');
  validateObjectKey(value, 'created');

  validateNumber(value.organization);
  validateNumber(value.device);
  validateNumber(value.created);
  validateArray(value.ios);

  value.ios.forEach((item) => {
    validateNonEmptyObject(item);
    validateObjectKey(item, 'id');
    validateObjectKey(item, 'value');

    validateNumber(item.id);
    validateNumber(item.value);
  });
}

/**
 * Connectin status changes
 */
export const parsePresenceMessage = (eventData: mqtt5.MessageReceivedEvent) => {
  try {
    if (eventData.message.payload) {
      const payload = JSON.parse(eventData.message.payload.toString()) as unknown;

      validateNonEmptyObject(payload);
      validateObjectKey(payload, 'clientId');
      validateObjectKey(payload, 'eventType');

      validateString(payload.clientId);

      const deviceId = extractDeviceIdFromClientId(payload.clientId);
      if (deviceId === null) {
        return null;
      }

      return {
        deviceId,
        isConnected: payload.eventType === 'connected',
      };
    }

    return null;
  } catch (e) {
    return null;
  }
};

/**
 * Growcast events
 * organization event or alarm
 */
export const parseEventMessage = (eventData: mqtt5.MessageReceivedEvent) => {
  try {
    if (eventData.message.payload) {
      const payload = JSON.parse(eventData.message.payload.toString()) as unknown;

      validateNonEmptyObject(payload);
      validateObjectKey(payload, 'type');
      validateObjectKey(payload, 'name');
      validateObjectKey(payload, 'organization');
      validateObjectKey(payload, 'device');

      validateString(payload.type);
      validateString(payload.name);
      validateNumber(payload.organization);
      validateNumber(payload.device);

      const { type, name, organization, device } = payload;

      return {
        created: new Date(), // this is hardcoded until hardware can handle offline events
        type,
        name,
        organization,
        device,
      };
    }

    return null;
  } catch (e) {
    debug('error parsing event message', e);
    return null;
  }
};

export const parseModuleConnectionEventMessage = (eventData: mqtt5.MessageReceivedEvent) => {
  try {
    if (eventData.message.payload) {
      const payload = JSON.parse(eventData.message.payload.toString()) as unknown;

      validateNonEmptyObject(payload);
      validateObjectKey(payload, 'type');
      validateObjectKey(payload, 'name');
      validateObjectKey(payload, 'organization');
      validateObjectKey(payload, 'device');
      validateObjectKey(payload, 'payload');

      validateString(payload.type);
      validateString(payload.name);
      validateNumber(payload.organization);
      validateNumber(payload.device);
      validateNonEmptyObject(payload.payload);
      validateObjectKey(payload.payload, 'module');
      validateNumber(payload.payload.module);

      const { type, name, organization, device } = payload;

      if (type === EventType.ORGANIZATION) {
        if (name === OrganizationEventName.MODULE_CONNECTED) {
          const moduleConnectedEvent: ModuleConnectedEvent = {
            type: EventType.ORGANIZATION,
            name: OrganizationEventName.MODULE_CONNECTED,
            organization,
            device,
            created: new Date().toISOString(), // this is hardcoded until hardware can handle offline events
            payload: { module: payload.payload.module },
          };
          return moduleConnectedEvent;
        }

        if (name === OrganizationEventName.MODULE_DISCONNECTED) {
          const moduleDisconnectedEvent: ModuleDisconnectedEvent = {
            type: EventType.ORGANIZATION,
            name: OrganizationEventName.MODULE_DISCONNECTED,
            organization,
            device,
            created: new Date().toISOString(), // this is hardcoded until hardware can handle offline events
            payload: { module: payload.payload.module },
          };
          return moduleDisconnectedEvent;
        }
      }
    }

    return null;
  } catch (e) {
    debug('error parsing module connection events', e);
    return null;
  }
};
