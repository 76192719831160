import { assertUnreachable, validateNonEmptyObject, validateNumber, validateObjectKey, validateString } from './validations';

export enum AlarmType {
  ABOVE_THRESHOLD = 'aboveThreshold',
  BELOW_THRESHOLD = 'belowThreshold',
  RANGE = 'range',
}

export enum DigitalAlarms {
  ON = 'on',
  OFF = 'off',
  STATECHANGE = 'statechange',
}

export interface ThresholdPayload {
  threshold: number;
}

export interface AboveThresholdAlarm {
  type: AlarmType.ABOVE_THRESHOLD;
  payload: ThresholdPayload;
}

export interface BelowThresholdAlarm {
  type: AlarmType.BELOW_THRESHOLD;
  payload: ThresholdPayload;
}

export interface RangePayload {
  min: number;
  max: number;
}

export interface RangeAlarm {
  type: AlarmType.RANGE;
  payload: RangePayload;
}

export type Alarm = AboveThresholdAlarm | BelowThresholdAlarm | RangeAlarm;

export class AlarmBuilder {
  static createAboveThresholdAlarm(threshold: number): AboveThresholdAlarm {
    return {
      type: AlarmType.ABOVE_THRESHOLD,
      payload: {
        threshold,
      },
    };
  }

  static createBelowThresholdAlarm(threshold: number): BelowThresholdAlarm {
    return {
      type: AlarmType.BELOW_THRESHOLD,
      payload: {
        threshold,
      },
    };
  }

  static createRangeAlarm(min: number, max: number): RangeAlarm {
    return {
      type: AlarmType.RANGE,
      payload: {
        min,
        max,
      },
    };
  }
}

export function isValidAlarmType(type: string): type is AlarmType {
  return Object.values(AlarmType).includes(type as AlarmType);
}

export function isDigitalAlarmType(value: unknown): value is DigitalAlarms {
  if (typeof value !== 'string') {
    return false;
  }

  return Object.values(DigitalAlarms).includes(value as DigitalAlarms);
}

export function validateAlarmType(type: string): asserts type is AlarmType {
  if (!isValidAlarmType(type)) {
    throw new Error(`Invalid alarm type: ${type}`);
  }
}

export function validateThresholdAlarmPayload(payload: unknown): asserts payload is ThresholdPayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'threshold');

  const { threshold } = payload;
  validateNumber(threshold);
}

export function validateRangeAlarmPayload(payload: unknown): asserts payload is RangePayload {
  validateNonEmptyObject(payload);
  validateObjectKey(payload, 'min');
  validateObjectKey(payload, 'max');

  const { min, max } = payload;
  validateNumber(min);
  validateNumber(max);
}

export function validateAlarm(alarm: unknown): asserts alarm is Alarm {
  validateNonEmptyObject(alarm);

  validateObjectKey(alarm, 'type');
  validateObjectKey(alarm, 'payload');

  const { type, payload } = alarm;

  validateString(type);
  validateAlarmType(type);

  switch (type) {
    case AlarmType.ABOVE_THRESHOLD:
    case AlarmType.BELOW_THRESHOLD:
      validateThresholdAlarmPayload(payload);
      break;

    case AlarmType.RANGE:
      validateRangeAlarmPayload(payload);
      break;

    default:
      assertUnreachable('invalid alarm type', type);
  }
}
