export enum Scenes {
  ANALYTICS = 'analytics',
  ORGANIZATION = 'organization',
  AUTOMATIONS = 'automations',
  BACKOFFICE = 'backoffice',
  CULTIVATION_LOGS = 'cultivationLogs',
  DEVICES = 'devices',
  MY_CULTIVATION = 'myCultivation',
  PROFILE = 'profile',
  SETUP = 'setup',
  SUPPORT = 'support',
  UNAUTH = 'unauth',
}

/**
 * This Sistemap implementations is to keep consistent all the routes and paths of the app
 *
 * Some paths are static like /devices and others, dynamic like /devices/:id
 * So, we may have 2 'paths' for the same page.
 * Our route path would be /devices/:id
 * but the actual value that we need for navigating must be built
 * by a buildPath fn.
 */

interface SingleStaticRoute {
  name: string;
  path: string;
}
interface SingleDynamicRoute {
  name: string;
  dynamicPath: string;
  buildPath?: (...args: Array<string>) => string;
}

type SingleRoute = SingleStaticRoute | SingleDynamicRoute;

interface NestedRoute {
  name: string;
  path: string;
  children: Record<string, Route>;
}

type Route = SingleRoute | NestedRoute;
export type Sitemap = Record<Scenes, Route>;

export function createSitemap<S extends Record<Scenes, Route>>(sitemap: S): S {
  return sitemap;
}

export const sitemap = createSitemap({
  [Scenes.ANALYTICS]: {
    name: 'Analitics',
    path: '/analitics',
  },
  [Scenes.AUTOMATIONS]: {
    name: 'Automations',
    path: '/automations',
    children: {
      advanced: {
        name: 'Advanced Automation',
        path: '/automations/advanced',
      },
      creator: {
        name: 'automations',
        path: '/automations/new',
      },
      viewer: {
        name: 'Automation View',
        dynamicPath: '/automations/:id',
        buildPath: (id: string) => `/automations/${id}`,
      },
    },
  },
  [Scenes.BACKOFFICE]: {
    name: 'Back office',
    path: '/backoffice',
    children: {
      contentManager: {
        name: 'Content manager',
        path: '/backoffice/content-manager',
        children: {
          hardware: {
            name: 'Hardware',
            path: '/backoffice/content-manager/hardware',
            children: {
              viewer: {
                name: 'Hardware viewer',
                buildPath: (id: string) => `/backoffice/content-manager/hardware/${id}`,
                dynamicPath: '/backoffice/content-manager/hardware/:id',
              },
            },
          },
          firmware: {
            name: 'Firmware',
            path: '/backoffice/content-manager/firmware',
            children: {
              viewer: {
                name: 'Firmware viewer',
                buildPath: (id: string) => `/backoffice/content-manager/firmware/${id}`,
                dynamicPath: '/backoffice/content-manager/firmware/:id',
              },
            },
          },
          variable: {
            name: 'Variables',
            path: '/backoffice/content-manager/variables',
            children: {
              viewer: {
                name: 'Variable viewer',
                buildPath: (id: string) => `/backoffice/content-manager/variables/${id}`,
                dynamicPath: '/backoffice/content-manager/variables/:id',
              },
            },
          },
          identifiers: {
            name: 'Identifiers',
            path: '/backoffice/content-manager/identifiers',
            children: {
              viewer: {
                name: 'Identifier Viewer',
                buildPath: (id: string) => `/backoffice/content-manager/identifiers/${id}`,
                dynamicPath: '/backoffice/content-manager/identifiers/:id',
              },
            },
          },
          zoneType: {
            name: 'Zone Type',
            path: '/backoffice/content-manager/zone-type',
            children: {
              viewer: {
                name: 'Zone Type viewer',
                buildPath: (id: string) => `/backoffice/content-manager/zone-type/${id}`,
                dynamicPath: '/backoffice/content-manager/zone-type/:id',
              },
            },
          },
          cultivationRecordType: {
            name: 'Cultivation Record Type',
            path: '/backoffice/content-manager/cultivation-record-type',
            children: {
              viewer: {
                name: 'Cultivation Record Type viewer',
                buildPath: (id: string) => `/backoffice/content-manager/cultivation-record-type/${id}`,
                dynamicPath: '/backoffice/content-manager/cultivation-record-type/:id',
              },
            },
          },
        },
      },
      stockManager: {
        name: 'Stock manager',
        path: '/backoffice/stock-manager',
        children: {
          lot: {
            name: 'Lot',
            path: '/backoffice/stock-manager/lot',
            children: {
              viewer: {
                name: 'Lot Viewer',
                buildPath: (id: string) => `/backoffice/stock-manager/lot/${id}`,
                dynamicPath: '/backoffice/stock-manager/lot/:id',
              },
            },
          },
          mainboard: {
            name: 'Mainboard',
            path: '/backoffice/stock-manager/mainboard',
            children: {
              viewer: {
                name: 'Mainboard Viewer',
                buildPath: (id: string) => `/backoffice/stock-manager/mainboard/${id}`,
                dynamicPath: '/backoffice/stock-manager/mainboard/:id',
              },
            },
          },
        },
      },
      tools: {
        name: 'tools',
        path: '/backoffice/utilities/tools',
      },
    },
  },
  [Scenes.CULTIVATION_LOGS]: {
    name: 'Cultivation logs',
    path: '/cultivation-logs',
    children: {
      viewer: {
        name: 'Cultivation Log Viewer',
        buildPath: (id: string) => `/cultivation-logs/${id}`,
        dynamicPath: '/cultivation-logs/:id',
      },
    },
  },
  [Scenes.DEVICES]: {
    name: 'Devices',
    path: '/devices',
    children: {
      devices: {
        name: 'Devices',
        buildPath: (id: string) => `/devices/${id}`,
        dynamicPath: '/devices/:id',
      },
      modules: {
        name: 'Modules',
        buildPath: (deviceId: string, moduleId: string) => `/devices/${deviceId}/modules/${moduleId}`,
        dynamicPath: '/devices/:deviceId/modules/:moduleId',
      },
      ios: {
        name: 'IOs',
        buildPath: (deviceId: string, moduleId: string, ioId: string) => `/devices/${deviceId}/modules/${moduleId}/ios/${ioId}`,
        dynamicPath: '/devices/:deviceId/modules/:moduleId/ios/:ioId',
      },
    },
  },
  [Scenes.MY_CULTIVATION]: {
    name: 'My cultivation',
    path: '/my-cultivation',
    children: {
      zone: {
        name: 'Zone',
        buildPath: (id: string) => `/my-cultivation/zone/${id}`,
        dynamicPath: '/my-cultivation/zone/:id',
      },
    },
  },
  [Scenes.ORGANIZATION]: {
    name: 'Organization',
    path: '/organization',
    children: {
      user: {
        name: 'User profile',
        buildPath: (username: string) => `/organization/${username}`,
        dynamicPath: '/organization/:username',
      },
    },
  },
  [Scenes.PROFILE]: {
    name: 'Profile',
    path: '/profile',
    children: {
      emailVerification: {
        path: '/profile/email-verification',
        name: 'Verify email address thru TOTP code',
      },
      notifications: {
        path: '/profile/notifications',
        name: 'Notifications',
      },
      phoneVerification: {
        path: '/profile/phone-number-verification',
        name: 'Verify phone number thru TOTP code',
      },
      preferences: {
        path: '/profile/preferences',
        name: 'User preferences',
      },
      security: {
        path: '/profile/security',
        name: 'Security',
      },
    },
  },
  [Scenes.SETUP]: {
    name: 'Setup',
    path: '/setup',
    children: {
      invite: {
        name: 'Invite',
        path: '/setup/invite',
      },
    },
  },
  [Scenes.SUPPORT]: {
    name: 'Support',
    path: '/support',
    children: {
      home: {
        name: 'Home',
        path: '/support/home',
      },
      issue: {
        name: 'Issue',
        buildPath: (id: string) => `/support/issue/${id}`,
        dynamicPath: '/support/issue/:id',
      },
    },
  },
  [Scenes.UNAUTH]: {
    name: 'Unauth scene',
    path: '/',
    children: {
      forgotPassword: {
        name: 'Forgot password',
        path: '/forgot-password',
      },
      mfa: {
        name: 'Multi-factor Authentication',
        path: '/multi-factor-authentication',
      },
      passwordRestore: {
        name: 'Password restore',
        path: '/password-restore',
      },
      signIn: {
        name: 'Log In',
        path: '/sign-in',
      },
      signUp: {
        name: 'Sign Up',
        path: '/sign-up',
      },
      signupConfirmation: {
        path: '/signup-confirmation',
        name: 'Sign Up Confirmation',
      },
    },
  },
});
