import { z } from 'zod';

/**
 * This is a tiny schema that contains only the necessary fields for devices setup and sync
 * We've to trim the output to improve memory use
 * Some names may be also modified
 */
const idAndName = z.object({
  id: z.number(),
  name: z.string().max(50),
});

export const ioOutputForDevices = idAndName.merge(
  z.object({
    isCritical: z.boolean(),
    isInput: z.boolean(),
    isAnalog: z.boolean(),
    identifier: z.number().optional(),
    manualState: z.boolean().optional(),
    automation: z.number().optional(),
    alarms: z.array(z.number()).optional(),
  })
);

export const moduleOutputForDevices = idAndName.merge(
  z.object({
    isBuiltIn: z.boolean(),
    identifier: z.number().optional(),
    ios: z.array(ioOutputForDevices),
  })
);

export const certificateOutputForDevices = z.object({
  cert: z.string(),
  privateKey: z.string(),
  ca: z.string(),
});

export const deviceOutputForDevices = z.object({
  id: z.number(),
  mac: z.string().length(17),
  organization: z.number(),
  modules: z.array(moduleOutputForDevices),
  utcOffsetInSeconds: z.number(),
  environment: z.string(),
});

export const setupOutputForDevices = deviceOutputForDevices.merge(
  z.object({
    certificate: certificateOutputForDevices,
    endpoint: z.string(),
  })
);

export const syncOutputForDevices = idAndName.merge(
  z.object({
    modules: z.array(
      idAndName.merge(
        z.object({
          ios: z.array(idAndName),
        })
      )
    ),
    utcOffsetInSeconds: z.number(),
  })
);

export type SyncOutputForDevices = z.infer<typeof syncOutputForDevices>;
export type SetupOutputForDevices = z.infer<typeof setupOutputForDevices>;
export type DeviceOutputForDevices = z.infer<typeof deviceOutputForDevices>;
export type ModuleOutputForDevices = z.infer<typeof moduleOutputForDevices>;
export type IOOutputForDevices = z.infer<typeof ioOutputForDevices>;
export type CertificateOutputForDevices = z.infer<typeof certificateOutputForDevices>;
