import { ColorMode } from './config';
import createDebugger from 'debug';
const debug = createDebugger('Growcast:updateMetaThemeContent');

export const updateMetaThemeContent = (newMode: ColorMode) => {
  const appBgHex = newMode === ColorMode.DARK ? '#131615' : '#DBDCD6';
  const metaThemeColor = document.querySelector('meta[name="theme-color"]');

  debug({ metaThemeColor });
  if (metaThemeColor !== null) {
    metaThemeColor.setAttribute('content', appBgHex);
  }
};
