import { NotificationChannel, validateNotificationChannel } from './notifications';
import { Permission } from './permissions';
import { validateArray, validateNonEmptyObject, validateString } from './validations';

export interface DecodedCognitoJWTPayload {
  aud: string;
  token_use: string;
  auth_time: number;
  iss: string;
  exp: number;
  iat: number;
  jti: string;
  sub: string;
  email_verified: boolean;
  email?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  updated_at?: number;
  created_at?: number;
  // custom attrs
  'cognito:username': string;
  'custom:permissions'?: string;
  'custom:preferredColorMode'?: string;
  'custom:defaultOrganization'?: string;
  'custom:invite'?: string;
  'custom:notificationChannels'?: string;
  'custom:telegramChatId'?: string;
}

/**
 * This is what we parse from the jwt in the session
 * THIS IS NOT what we saved in the database. that's on api/users/utils
 */
export interface SessionUser {
  username: string;
  sub: string;
  email: string;
  isEmailVerified: boolean;
  phoneNumber?: string;
  isPhoneNumberVerified: boolean;
  givenName: string;
  familyName: string;
  locale: string;
  permissions: Permission;
  invite?: number;
  defaultOrganization?: number;
  telegramChatId?: string;
  preferredColorMode?: string;
  notificationChannels?: Array<NotificationChannel>;
}

export interface SessionUserWithDefaultOrganization extends SessionUser {
  defaultOrganization: number;
}

export const parseCognitoPayload = (payload: unknown): SessionUser => {
  validateNonEmptyObject(payload);

  if (!payload['cognito:username']) {
    throw new Error(`username is missing`);
  }

  if (!payload.sub) {
    throw new Error(`sub is missing`);
  }

  if (!payload?.email) {
    throw new Error(`email is missing`);
  }

  if (!payload?.given_name) {
    throw new Error(`given_name is missing`);
  }

  if (!payload?.family_name) {
    throw new Error(`family_name is missing`);
  }

  if (!payload?.locale) {
    throw new Error(`locale is missing`);
  }

  const permissions = parsePermissionsAttr(payload['custom:permissions']);
  const notificationChannels = parseNotificationChannels(payload['custom:notificationChannels']);
  const defaultOrganization = Number(payload['custom:defaultOrganization']);
  const invite = Number(payload['custom:invite']);

  return {
    username: payload['cognito:username'] as string,
    sub: payload.sub as string,
    email: payload.email as string,
    phoneNumber: payload?.phone_number as string,
    givenName: payload.given_name as string,
    familyName: payload.family_name as string,
    locale: payload.locale as string,
    isEmailVerified: !!payload.email_verified,
    isPhoneNumberVerified: !!payload.phone_number_verified,
    // custom attrs
    permissions,
    notificationChannels,
    invite: isNaN(invite) || invite === 0 ? undefined : invite,
    preferredColorMode: payload['custom:preferredColorMode'] as string,
    telegramChatId: payload['custom:telegramChatId'] as string,
    defaultOrganization: isNaN(defaultOrganization) || defaultOrganization === 0 ? undefined : defaultOrganization,
  };
};

const parsePermissionsAttr = (attr: unknown): Permission => {
  try {
    const permissions = new Permission(attr as string);

    return permissions;
  } catch (error) {
    return new Permission(null);
  }
};

export const parseNotificationChannels = (attr: unknown): Array<NotificationChannel> | undefined => {
  try {
    validateString(attr);

    const obj = JSON.parse(attr) as unknown;
    validateArray(obj);

    const channels: Array<NotificationChannel> = obj.map((item) => {
      validateString(item);
      validateNotificationChannel(item);

      return item;
    });

    return channels;
  } catch (error) {
    return undefined;
  }
};
