import { AppRouter } from './routing/Router';
import { SessionProvider } from './session';
import { ThemeProvider } from './theme';
import { TranslationProvider } from './i18n';
import { APIProviver } from './api/APIProvider';
import './assets/fonts/config-fonts.css';

function App() {
  return (
    <SessionProvider>
      <TranslationProvider>
        <APIProviver>
          <ThemeProvider>
            <AppRouter />
          </ThemeProvider>
        </APIProviver>
      </TranslationProvider>
    </SessionProvider>
  );
}
export default App;
