import { MessageDescriptor, defineMessages } from 'react-intl';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { getEnvs } from '../utils/getEnvs';

export const getUserPool = (): CognitoUserPool => {
  const { userPoolId, userPoolWebClientId } = getEnvs();

  const pool = new CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: userPoolWebClientId,
  });

  return pool;
};

export enum CognitoErrorCode {
  CodeDeliveryFailureException = 'CodeDeliveryFailureException',
  ForbiddenException = 'ForbiddenException',
  InternalErrorException = 'InternalErrorException',
  InvalidEmailRoleAccessPolicyException = 'InvalidEmailRoleAccessPolicyException',
  InvalidLambdaResponseException = 'InvalidLambdaResponseException',
  InvalidParameterException = 'InvalidParameterException',
  InvalidPasswordException = 'InvalidPasswordException',
  InvalidSmsRoleAccessPolicyException = 'InvalidSmsRoleAccessPolicyException',
  InvalidSmsRoleTrustRelationshipException = 'InvalidSmsRoleTrustRelationshipException',
  NotAuthorizedException = 'NotAuthorizedException',
  ResourceNotFoundException = 'ResourceNotFoundException',
  TooManyRequestsException = 'TooManyRequestsException',
  UnexpectedLambdaException = 'UnexpectedLambdaException',
  UserLambdaValidationException = 'UserLambdaValidationException',
  UsernameExistsException = 'UsernameExistsException',
  CodeMismatchException = 'CodeMismatchException',
  UserNotFoundException = 'UserNotFoundException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  TooManyFailedAttemptsException = 'TooManyFailedAttemptsException',
  InvalidVerificationCodeException = 'InvalidVerificationCodeException',
  ExpiredCodeException = 'ExpiredCodeException',
  LimitExceededException = 'LimitExceededException',
  InvalidUserPoolConfigurationException = 'InvalidUserPoolConfigurationException',
  AliasExistsException = 'AliasExistsException',
  UserNotConfirmedException = 'UserNotConfirmedException',
}

export const cognitoErrorMessages = defineMessages({
  codeDeliveryFailureException: {
    id: 'CognitoError.code_delivery_failure_exception',
    defaultMessage: 'No pudimos enviar el código de verificación. Por favor verifica tus detalles de contacto.',
  },
  forbiddenException: {
    id: 'CognitoError.forbidden_exception',
    defaultMessage: 'No tienes los permisos necesarios para realizar esta operación.',
  },
  internalErrorException: {
    id: 'CognitoError.internal_error_exception',
    defaultMessage: 'Ocurrió un error interno. Por favor intenta de nuevo más tarde.',
  },
  invalidEmailRoleAccessPolicyException: {
    id: 'CognitoError.invalid_email_role_access_policy_exception',
    defaultMessage: 'Parece haber un problema con nuestra configuración de correo electrónico. Por favor contacta al soporte.',
  },
  invalidLambdaResponseException: {
    id: 'CognitoError.invalid_lambda_response_exception',
    defaultMessage: 'Ocurrió un error interno. Por favor intenta de nuevo más tarde.',
  },
  invalidParameterException: {
    id: 'CognitoError.invalid_parameter_exception',
    defaultMessage: 'Tu solicitud tiene parámetros inválidos. Por favor verifica tus entradas e intenta de nuevo.',
  },
  invalidPasswordException: {
    id: 'CognitoError.invalid_password_exception',
    defaultMessage: 'Tu contraseña es inválida. Por favor sigue las reglas de contraseña e intenta de nuevo.',
  },
  invalidSmsRoleAccessPolicyException: {
    id: 'CognitoError.invalid_sms_role_access_policy_exception',
    defaultMessage: 'Parece haber un problema con nuestra configuración de SMS. Por favor contacta al soporte.',
  },
  invalidSmsRoleTrustRelationshipException: {
    id: 'CognitoError.invalid_sms_role_trust_relationship_exception',
    defaultMessage: 'Ocurrió un error interno. Por favor intenta de nuevo más tarde.',
  },
  notAuthorizedException: {
    id: 'CognitoError.not_authorized_exception',
    defaultMessage: 'No estás autorizado para realizar esta operación. Verifica tus credenciales e intenta de nuevo.',
  },
  resourceNotFoundException: {
    id: 'CognitoError.resource_not_found_exception',
    defaultMessage: 'No pudimos encontrar el recurso que solicitaste. Por favor verifica tus entradas e intenta de nuevo.',
  },
  tooManyRequestsException: {
    id: 'CognitoError.too_many_requests_exception',
    defaultMessage: 'Has hecho demasiadas solicitudes. Por favor espera un momento e intenta de nuevo.',
  },
  unexpectedLambdaException: {
    id: 'CognitoError.unexpected_lambda_exception',
    defaultMessage: 'Ocurrió un error interno. Por favor intenta de nuevo más tarde.',
  },
  userLambdaValidationException: {
    id: 'CognitoError.user_lambda_validation_exception',
    defaultMessage: 'Ocurrió un error interno. Por favor intenta de nuevo más tarde.',
  },
  usernameExistsException: {
    id: 'CognitoError.username_exists_exception',
    defaultMessage: 'Este nombre de usuario ya existe. Si olvidaste tu contraseña, puedes restablecerla.',
  },
  codeMismatchException: {
    id: 'CognitoError.code_mismatch_exception',
    defaultMessage: 'El código que ingresaste no coincide con nuestros registros. Por favor verifica e intenta de nuevo.',
  },
  userNotFoundException: {
    id: 'CognitoError.user_not_found_exception',
    defaultMessage: 'No pudimos encontrar un usuario con ese nombre de usuario. Por favor verifica tus entradas e intenta de nuevo.',
  },
  passwordResetRequiredException: {
    id: 'CognitoError.password_reset_required_exception',
    defaultMessage: 'Necesitas restablecer tu contraseña. Por favor verifica tu correo electrónico para obtener instrucciones.',
  },
  tooManyFailedAttemptsException: {
    id: 'CognitoError.too_many_failed_attempts_exception',
    defaultMessage: 'Ha habido demasiados intentos fallidos para acceder a tu cuenta. Por favor restablece tu contraseña.',
  },
  invalidVerificationCodeException: {
    id: 'CognitoError.invalid_verification_code_exception',
    defaultMessage: 'El código de verificación que ingresaste no es válido. Por favor verifica e intenta de nuevo.',
  },
  expiredCodeException: {
    id: 'CognitoError.expired_code_exception',
    defaultMessage: 'El código de verificación que ingresaste ha expirado. Por favor solicita un nuevo código.',
  },
  limitExceededException: {
    id: 'CognitoError.limit_exceeded_exception',
    defaultMessage: 'Has superado el límite para esta operación. Por favor intenta de nuevo más tarde.',
  },
  invalidUserPoolConfigurationException: {
    id: 'CognitoError.invalid_user_pool_configuration_exception',
    defaultMessage: 'Ocurrió un error interno. Por favor intenta de nuevo más tarde.',
  },
  aliasExistsException: {
    id: 'CognitoError.alias_exists_exception',
    defaultMessage: 'Este alias ya existe. Por favor utiliza otro.',
  },
  userNotConfirmed: {
    id: 'CognitoError.user_not_confirmed_exception',
    defaultMessage: 'Tu cuenta aún no ha sido confirmada. Por favor verifica tu correo electrónico para obtener un código de confirmación.',
  },
  unknownError: {
    id: 'CognitoError.unknown_error',
    defaultMessage: 'Ocurrió un error desconocido. Por favor intenta de nuevo más tarde.',
  },
  userDisabled: {
    id: 'CognitoError.user_disabled',
    defaultMessage: 'Tu cuenta ha sido deshabilitada. Por favor, contacta al administador de tu organización.',
  },
  incorrectUserNameOrPassword: {
    id: 'CognitoError.incorrect_username_or_password',
    defaultMessage: 'Usuario o contraseña incorrectos. Por favor verifica e intenta de nuevo.',
  },
});

export const getCognitoErrorTranslation = (error: unknown): MessageDescriptor => {
  if (!isCognitoError(error)) {
    return cognitoErrorMessages.unknownError;
  }

  const { code, message } = error;

  switch (code) {
    case 'CodeDeliveryFailureException':
      return cognitoErrorMessages.codeDeliveryFailureException;

    case 'ForbiddenException':
      return cognitoErrorMessages.forbiddenException;

    case 'InternalErrorException':
      return cognitoErrorMessages.internalErrorException;

    case 'InvalidEmailRoleAccessPolicyException':
      return cognitoErrorMessages.invalidEmailRoleAccessPolicyException;

    case 'InvalidLambdaResponseException':
      return cognitoErrorMessages.invalidLambdaResponseException;

    case 'InvalidParameterException':
      return cognitoErrorMessages.invalidParameterException;

    case 'InvalidPasswordException':
      return cognitoErrorMessages.invalidPasswordException;

    case 'InvalidSmsRoleAccessPolicyException':
      return cognitoErrorMessages.invalidSmsRoleAccessPolicyException;

    case 'InvalidSmsRoleTrustRelationshipException':
      return cognitoErrorMessages.invalidSmsRoleTrustRelationshipException;

    case 'NotAuthorizedException': {
      if (message === 'User is disabled.') {
        return cognitoErrorMessages.userDisabled;
      }
      if (message === 'Incorrect username or password.') {
        return cognitoErrorMessages.incorrectUserNameOrPassword;
      }

      return cognitoErrorMessages.notAuthorizedException;
    }

    case 'ResourceNotFoundException':
      return cognitoErrorMessages.resourceNotFoundException;

    case 'TooManyRequestsException':
      return cognitoErrorMessages.tooManyRequestsException;

    case 'UnexpectedLambdaException':
      return cognitoErrorMessages.unexpectedLambdaException;

    case 'UserLambdaValidationException':
      return cognitoErrorMessages.userLambdaValidationException;

    case 'UsernameExistsException':
      return cognitoErrorMessages.usernameExistsException;

    case 'CodeMismatchException':
      return cognitoErrorMessages.codeMismatchException;

    case 'UserNotFoundException':
      return cognitoErrorMessages.userNotFoundException;

    case 'PasswordResetRequiredException':
      return cognitoErrorMessages.passwordResetRequiredException;

    case 'TooManyFailedAttemptsException':
      return cognitoErrorMessages.tooManyFailedAttemptsException;

    case 'InvalidVerificationCodeException':
      return cognitoErrorMessages.invalidVerificationCodeException;

    case 'ExpiredCodeException':
      return cognitoErrorMessages.expiredCodeException;

    case 'LimitExceededException':
      return cognitoErrorMessages.limitExceededException;

    case 'InvalidUserPoolConfigurationException':
      return cognitoErrorMessages.invalidUserPoolConfigurationException;

    case 'AliasExistsException':
      return cognitoErrorMessages.aliasExistsException;

    case 'UserNotConfirmedException':
      return cognitoErrorMessages.userNotConfirmed;

    default:
      return cognitoErrorMessages.unknownError;
  }
};

export interface WithCodeDeliveryDetails {
  CodeDeliveryDetails: {
    Destination: string;
    AttributeName: string;
    DeliveryMedium: string;
  };
}

export const hasCodeDeliveryDetails = (value: unknown): value is WithCodeDeliveryDetails => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'CodeDeliveryDetails' in value &&
    typeof value.CodeDeliveryDetails === 'object' &&
    value.CodeDeliveryDetails !== null &&
    'Destination' in value.CodeDeliveryDetails &&
    'AttributeName' in value.CodeDeliveryDetails &&
    'DeliveryMedium' in value.CodeDeliveryDetails &&
    typeof value.CodeDeliveryDetails.Destination === 'string' &&
    typeof value.CodeDeliveryDetails.AttributeName === 'string' &&
    typeof value.CodeDeliveryDetails.DeliveryMedium === 'string'
  );
};

export interface CognitoError {
  code: CognitoErrorCode;
  message: string;
}

export const isValidCognitoErrorCode = (code: string) => {
  return Object.values(CognitoErrorCode).includes(code as CognitoErrorCode);
};

export function isCognitoError(value: unknown): value is CognitoError {
  return (
    typeof value === 'object' &&
    value !== null &&
    'code' in value &&
    'message' in value &&
    typeof value.code === 'string' &&
    typeof value.message === 'string' &&
    isValidCognitoErrorCode(value.code)
  );
}
