import React, { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { API, APIOutputs } from '../../api/trpc';
import { getQueryKey } from '@trpc/react-query';
import { useSessionUser } from '../../session';
import { DeviceUpdateOutput } from '../Devices/useUpdateDevice';
import { IOUpdateOutput } from '../IOs/useUpdateIO';
import { ModulesUpdateOutput } from '../Modules/useUpdateModule';
import { UpdateOrganizationOutput } from './UpdateOrganizationMenuItem';
import { CreateModulesOutput } from '../Modules/AddModuleStepper/useCreateModule';
import { NewDeviceOutput } from '../Devices/AddDeviceStepper/useCreateDevice';

export type MyOrganizationOutput = APIOutputs['organizations']['get'];
export type MyOrganizationOutputDevice = MyOrganizationOutput['Device'][number];
export type MyOrganizationOutputModule = MyOrganizationOutputDevice['Module'][number];
export type MyOrganizationOutputIO = MyOrganizationOutputModule['IO'][number];
export type MyOrganizationOutputIOHardwareCapability = MyOrganizationOutputIO['HardwareCapability'];
export type MyOrganizationOutputIOHardwareDescriptionObject = MyOrganizationOutputIOHardwareCapability['IOHardwareDescriptionObject'];

export function useMyOrganization() {
  const user = useSessionUser();

  const [, startTransition] = React.useTransition();

  const queryClient = useQueryClient();

  const fetchQueryKey = useMemo(
    () => getQueryKey(API.organizations.get, { id: Number(user.defaultOrganization) }, 'query'),
    [user.defaultOrganization]
  );

  const { data, error, isLoading, isFetching, refetch } = API.organizations.get.useQuery({ id: Number(user.defaultOrganization) });

  /**
   * Getters
   */
  const getDevice = (id: number): MyOrganizationOutputDevice | null => {
    const device = data?.Device.find((device) => device.id === id);

    return device ?? null;
  };

  const getModule = (deviceId: number, id: number): MyOrganizationOutputModule | null => {
    const device = getDevice(deviceId);

    if (!device) {
      return null;
    }

    const module = device.Module.find((module) => module.id === id);

    return module ?? null;
  };

  const getIO = (deviceId: number, id: number): MyOrganizationOutputIO | null => {
    const device = getDevice(deviceId);
    if (!device) {
      return null;
    }

    type ReducedType = MyOrganizationOutputIO | null;
    const initialValue: ReducedType = null;

    const io = device.Module.reduce((io: ReducedType, module) => {
      const found = module.IO.find((io) => id === io.id);
      if (found) {
        return found;
      }

      return io;
    }, initialValue);

    return io;
  };

  /**
   * Cache updates
   */
  const onOrganizationUpdate = (response: UpdateOrganizationOutput) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, () => response);
    });
  };

  /**
   * Device
   */
  const onDeviceCreate = (newDevice: NewDeviceOutput) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          return {
            ...old,
            Device: [...old.Device, newDevice],
          };
        }
      });
    });
  };

  const onDeviceUpdate = (newDevice: DeviceUpdateOutput) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (newDevice.id === device.id) {
              return {
                ...device,
                name: newDevice.name,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    });
  };

  const onDeviceSync = (deviceId: number) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (deviceId === device.id) {
              const updatedModules = device.Module.map((module) => ({
                ...module,
                isSynchronized: true,
              }));

              return {
                ...device,
                Module: updatedModules,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    });
  };

  const onDeviceFirmwareUpdate = (deviceId: number, firmware: number) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (deviceId === device.id) {
              return {
                ...device,
                firmware: firmware,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    });
  };

  const onDeviceConnected = React.useCallback(
    (deviceId: number) => {
      startTransition(() => {
        queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
          if (old) {
            const updatedDevices = old.Device.map((device) => {
              if (deviceId === device.id) {
                const updatedModules = device.Module.map((module) => ({
                  ...module,
                  isConnected: true,
                }));

                return {
                  ...device,
                  isConnected: true,
                  Module: updatedModules,
                };
              }

              return device;
            });

            return {
              ...old,
              Device: updatedDevices,
            };
          }
        });
      });
    },
    [fetchQueryKey, queryClient]
  );

  const onDeviceConnectionConfirmed = (deviceId: number) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (deviceId === device.id) {
              const updatedModules = device.Module.map((module) => ({
                ...module,
                isConnected: true,
                isSynchronized: true,
              }));

              return {
                ...device,
                isConnected: true,
                Module: updatedModules,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    });
  };

  const onDeviceDisconnected = React.useCallback(
    (deviceId: number) => {
      startTransition(() => {
        queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
          if (old) {
            const updatedDevices = old.Device.map((device) => {
              if (deviceId === device.id) {
                return {
                  ...device,
                  isConnected: false,
                };
              }

              return device;
            });

            return {
              ...old,
              Device: updatedDevices,
            };
          }
        });
      });
    },
    [fetchQueryKey, queryClient]
  );

  /**
   * Module
   */
  const onModuleUpdate = (newModule: ModulesUpdateOutput) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (newModule.device === device.id) {
              const updatedModule = device.Module.map((module) =>
                module.id === newModule.id
                  ? {
                      ...module,
                      name: newModule.name,
                      description: newModule.description,
                    }
                  : module
              );

              return {
                ...device,
                Module: updatedModule,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    });
  };

  const onModuleCreate = (newModule: CreateModulesOutput) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (newModule.device === device.id) {
              const addedModule = {
                ...newModule,
                isConnected: false,
                isSynchronized: false,
              };

              return {
                ...device,
                Module: [...device.Module, addedModule],
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    });
  };

  const onModuleSync = (deviceId: number, moduleId: number) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (deviceId === device.id) {
              const updatedModules = device.Module.map((module) =>
                module.id === moduleId
                  ? {
                      ...module,
                      isSynchronized: true,
                      isConnected: true,
                    }
                  : module
              );

              return {
                ...device,
                Module: updatedModules,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    });
  };

  const onModuleConfirmedByDevice = useCallback(
    (deviceId: number, moduleId: number) => {
      startTransition(() => {
        queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
          if (old) {
            const updatedDevices = old.Device.map((device) => {
              if (deviceId === device.id) {
                const updatedModules = device.Module.map((module) =>
                  module.id === moduleId
                    ? {
                        ...module,
                        isSynchronized: true,
                        isConnected: true,
                      }
                    : module
                );

                return {
                  ...device,
                  Module: updatedModules,
                };
              }

              return device;
            });

            return {
              ...old,
              Device: updatedDevices,
            };
          }
        });
      });
    },
    [fetchQueryKey, queryClient]
  );

  const onModuleDelete = (deletedModuleId: number) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            return {
              ...device,
              Module: device.Module.filter((module) => module.id !== deletedModuleId),
            };
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    });
  };

  const onModuleDisconnected = React.useCallback(
    (deviceId: number, moduleId: number) => {
      startTransition(() => {
        queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
          if (old) {
            const updatedDevices = old.Device.map((device) => {
              if (deviceId === device.id) {
                const updatedModule = device.Module.map((module) => {
                  if (module.id === moduleId) {
                    return {
                      ...module,
                      isConnected: false,
                    };
                  }

                  return module;
                });

                return {
                  ...device,
                  Module: updatedModule,
                };
              }

              return device;
            });

            return {
              ...old,
              Device: updatedDevices,
            };
          }
        });
      });
    },
    [fetchQueryKey, queryClient]
  );

  const onModuleConnected = React.useCallback(
    (deviceId: number, moduleId: number) => {
      startTransition(() => {
        queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
          if (old) {
            const updatedDevices = old.Device.map((device) => {
              if (deviceId === device.id) {
                const updatedModule = device.Module.map((module) => {
                  if (module.id === moduleId) {
                    return {
                      ...module,
                      isConnected: true,
                    };
                  }

                  return module;
                });

                return {
                  ...device,
                  Module: updatedModule,
                };
              }

              return device;
            });

            return {
              ...old,
              Device: updatedDevices,
            };
          }
        });
      });
    },
    [fetchQueryKey, queryClient]
  );

  /**
   * IO
   */
  const onIOUpdate = (newIO: IOUpdateOutput) => {
    startTransition(() => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            const updatedModules = device.Module.map((module) => {
              if (module.id === newIO.module) {
                const updatedIOs = module.IO.map((io) =>
                  io.id === newIO.id
                    ? {
                        ...io,
                        name: newIO.name,
                        description: newIO.description,
                      }
                    : io
                );

                return {
                  ...module,
                  IO: updatedIOs,
                };
              }

              return module;
            });

            return {
              ...device,
              Module: updatedModules,
            };
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    });
  };

  // this is because we call to ensure data in the autu router layout component
  if (data === undefined) {
    throw new Error('useMyOrganization was called out of its router');
  }
  return {
    data,
    error,
    isLoading,
    isFetching,
    getDevice,
    getModule,
    getIO,
    onOrganizationUpdate,
    onDeviceCreate,
    onDeviceUpdate,
    onDeviceSync,
    onDeviceFirmwareUpdate,
    onDeviceConnected,
    onDeviceDisconnected,
    onDeviceConnectionConfirmed,
    onModuleConnected,
    onModuleDisconnected,
    onModuleCreate,
    onModuleUpdate,
    onModuleDelete,
    onModuleSync,
    onModuleConfirmedByDevice,
    onIOUpdate,
    refetch,
  };
}
